/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { useState } from 'react';
import { Dialog, Grid, Box } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { StyledToolbarGrid, StyledFileUploadButton } from './users.styled';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AddUserComponent } from '../../components/addUser.component';
const CustomToolbar = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <StyledToolbarGrid container>
        <Grid item xs={12} sm={4} md={'auto'}>
          <GridToolbarQuickFilter />
        </Grid>
        <Grid item xs={12} sm={4} md={'auto'}>
          <StyledFileUploadButton
            variant="contained"
            size="medium"
            onClick={handleOpen}
            startIcon={<Add />}
          >
            {t('usersPage.dataGrid.customToolbar.add')}
          </StyledFileUploadButton>
        </Grid>
      </StyledToolbarGrid>

      <Dialog
        onClose={handleClose}
        open={open}
        style={{ width: '350px', margin: 'auto' }}
      >
        <AddUserComponent handleClose={handleClose} />
      </Dialog>
    </Box>
  );
};

export default CustomToolbar;
