/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useEffect, useState } from 'react';
import {
  Toolbar,
  IconButton,
  Container,
  Button,
  Popover,
  Icon,
  Box,
  Tooltip,
  Grid,
  Divider,
} from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { setUserRole } from '../../redux/reducers/userRole.slice';
import { AppNavbarProfile } from './app.navbar.profile.component';
import {
  AppNavBar,
  StyledAvatar,
  AvatarLetterWrap,
  BoxWrap,
  BoxWrapMobile,
  SubtitleWrap,
  LogoWrap,
  StyledAvatarAndModelContainer,
  StyledModelContainer,
  StyledNameTypography,
  StyledModelTypography,
} from './app.navbar.styled';
import { ReactComponent as ProfileIcon } from '../../assets/profile-icon.svg';
// import { ReactComponent as AskLogo } from '../../assets/ask-logo.svg';
import JLLlogo from '../../assets/jll-logo.png';
import * as PATHS from '../../constants/path';
import {
  useLazyGetSearchEnginesQuery,
  useLazyGetUserbyEmailQuery,
  useGetFileCollectionsQuery,
} from '../../redux/services/speciphicAsk';
import { useSelector, useDispatch } from 'react-redux';
import NirmalAvatar from '../../assets/nirmal-avatar.svg';
import { SUJIT_EMAIL, USER } from '../../constants/roles';
import Dropdown from '../dropdown';
import { FileCollectionContext } from '../../hooks/useContext';
import { setSelectedFileCollection } from '../../redux/reducers/fileCollection.slice';
import { useContext } from 'react';
import DropdownOne from '../dropdownone/dropdownone';

const AppNavbar = React.forwardRef((props, ref) => {
  const [fileCollectionId, setFileCollectionId] = useState('');
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useAuth();
  const [searchEngine, setSearchEngine] = React.useState('');
  const fileCollection = useSelector((state) => state.fileCollection);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [getSearchEngines, result] = useLazyGetSearchEnginesQuery();
  const [getUser, user] = useLazyGetUserbyEmailQuery();
  const { updateCollectionId } = useContext(FileCollectionContext);
  const dropdownVisible = window.location.pathname === PATHS.HOMEPAGE;

  useEffect(() => {
    if (user.data) {
      dispatch(setUserRole(user.data.role));
    } else {
      dispatch(setUserRole(USER));
    }
  }, [user]);

  const {
    data: fileCollections = [],
    isError: isGetFileCollectionsError,
    isLoading: isGetFileCollectionsLoading,
    isSuccess: isGetFileCollectionsSuccess,
  } = useGetFileCollectionsQuery({
    include: ['name', 'searchEngine'],
    isStoreCreated: true,
  });
  useEffect(() => {
    setFileCollectionId(
      search.get('id')
        ? search.get('id')
        : fileCollections.length
        ? fileCollections[0]?.id
        : '',
    );
  }, [fileCollections]);

  useEffect(() => {
    updateCollectionId(fileCollectionId);
    dispatch(
      setSelectedFileCollection(
        fileCollections.find(
          (fileCollection) => fileCollection.id === fileCollectionId,
        ),
      ),
    );
  }, [fileCollectionId]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      getUser({ emailId: auth.user.profile.preferred_username });
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (auth.isAuthenticated && location.pathname.includes(PATHS.HOMEPAGE)) {
      getSearchEngines();
    }
  }, [auth.isAuthenticated, getSearchEngines, location.pathname]);

  useEffect(() => {
    if (result) {
      const searchEngines = result?.data?.find((searchEngine) => {
        return searchEngine.value === fileCollection.searchEngine;
      });
      const label = searchEngines?.label;
      setSearchEngine(label);
    }
  }, [result, fileCollection.searchEngine]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      auth.signinSilent();
    }
  }, []);

  const handleSignInClick = () => {
    auth.signinRedirect();
  };

  const handleLogoutClick = () => {
    auth.signoutRedirect();
    if (auth.isAuthenticated) {
      auth.removeUser();
    }
    handleCloseUserMenuClick();
    // navigate(PATHS.LOGOUT);
  };

  const handleProfileClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.PROFILE);
  };

  const handleSearchHistoryClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.SEARCH_HISTORY);
  };

  const handleSettingsClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.SETTINGS);
  };

  const handleUsersClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.USERS);
  };

  const handleHomeClick = () => {
    navigate(PATHS.HOMEPAGE);
  };

  const handleOpenUserMenuClick = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenuClick = () => {
    setAnchorElUser(null);
  };

  const itemsForFileCollection = fileCollections.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const handleFileChange = (value) => {
    setFileCollectionId(value);
    updateCollectionId(value);
    dispatch(
      setSelectedFileCollection(
        fileCollections.find((fileCollection) => fileCollection.id === value),
      ),
    );
    navigate(PATHS.HOMEPAGE);
  };

  return (
    <AppNavBar ref={ref} position="sticky">
      <Container maxWidth="100%">
        <Toolbar disableGutters>
          <Grid container spacing={2}>
            <Grid item sm={8} display={'flex'} alignItems={'center'}>
              <BoxWrap>
                <Box onClick={() => navigate('/')}>
                  <img src={JLLlogo} alt="JLLlogo" width="50%" />
                </Box>
              </BoxWrap>

              <BoxWrapMobile href="/">
                <LogoWrap variant="h2" onClick={handleHomeClick}>
                  <img src={JLLlogo} alt="JLLlogo" width="80%" />
                </LogoWrap>
              </BoxWrapMobile>
            </Grid>
            {auth.isAuthenticated ? (
              <Grid
                item
                sm={4}
                container
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Grid
                  item
                  sm={7}
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                >
                  {dropdownVisible && (
                    <Dropdown
                      items={itemsForFileCollection}
                      onChange={handleFileChange}
                      value={fileCollectionId}
                      noOptionsMessage={t(
                        'trainedquestionAnswerPage.fileCollectionDropdown.noOptionsMessage',
                      )}
                      placeholder={t(
                        'trainedquestionAnswerPage.fileCollectionDropdown.placeholder',
                      )}
                      isLoading={
                        search.get('id') ? false : isGetFileCollectionsLoading
                      }
                      loadingMessage={t(
                        'trainedquestionAnswerPage.fileCollectionDropdown.loadingMessage',
                      )}
                      searchEnginePlaceholder={searchEngine ? searchEngine : ''}
                      label={false}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  sm={5}
                  // sm={3}
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  {' '}
                  <Divider
                    orientation="vertical"
                    sx={{ ml: 1, mr: 1, height: '50%', width: '1px' }}
                  />
                  <BoxWrap>
                    <StyledModelContainer>
                      <StyledNameTypography variant="subtitle1">
                        {auth.user.profile.name
                          ? auth.user.profile.name
                          : t('app.navbar.accountText.primary')}
                      </StyledNameTypography>
                    </StyledModelContainer>
                  </BoxWrap>
                  <IconButton onClick={handleOpenUserMenuClick} sx={{ p: 0 }}>
                    {auth.user.profile?.preferred_username === SUJIT_EMAIL ? (
                      <StyledAvatar alt="Sujit" src={NirmalAvatar} />
                    ) : (
                      <StyledAvatar>
                        <AvatarLetterWrap>
                          {auth.user.profile.name?.charAt(0) ||
                            auth.user.profile.oid?.charAt(0)}
                        </AvatarLetterWrap>
                      </StyledAvatar>
                    )}
                  </IconButton>
                  <Popover
                    sx={{ mt: 2 }}
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenuClick}
                  >
                    <AppNavbarProfile
                      userName={auth.user.profile?.name}
                      userEmail={auth.user.profile?.preferred_username}
                      profileClick={handleProfileClick}
                      settingsClick={handleSettingsClick}
                      logoutClick={handleLogoutClick}
                      searchHistoryClick={handleSearchHistoryClick}
                      usersClick={handleUsersClick}
                    />
                  </Popover>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item sm={4} display={'flex'} justifyContent={'flex-end'}>
                  <BoxWrapMobile>
                    <Tooltip title={t('app.navbar.signInbuttonText')}>
                      <IconButton color="primary" onClick={handleSignInClick}>
                        <ProfileIcon />
                      </IconButton>
                    </Tooltip>
                  </BoxWrapMobile>
                  {/* Desktop devices */}
                  <BoxWrap>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleSignInClick}
                      size="large"
                      endIcon={<Icon component={ProfileIcon} />}
                    >
                      {t('app.navbar.signInbuttonText')}
                    </Button>
                  </BoxWrap>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </Container>
    </AppNavBar>
  );
});

AppNavbar.displayName = 'AppNavbar';

export default AppNavbar;
