export const HOMEPAGE = '/';
export const NOT_FOUND = '*';
export const PROFILE = '/profile';
export const LEGAL = '/legal';
export const SERVICES = '/services';
export const SPECIPHIC_ASK = '/search';
export const SEARCH_HISTORY = '/search-history';
export const SETTINGS = '/settings';
export const USERS = '/users';
export const ADD_USER = '/users/add-user';
export const LOGOUT = '/logout';
export const SETTINGS_FILE_UPLOAD = `${SETTINGS}/create-file-collection`;
export const SETTINGS_FILE_COLLECTIONS = `${SETTINGS}/file-collections`;
export const FLEXDAY_WEBSITE = 'https://flexdaysolutions.com';
export const FLEXDAY_MAIL = 'mailto:info@weareflexday.com';
export const FLEXDAY_SUPPORT = 'mailto:support@weareflexday.com';
export const FLEXDAY_CONTACT = 'https://calendly.com/flexhima';
export const FLEXDAY_FACEBOOK = 'https://www.facebook.com/weareflexday';
export const FLEXDAY_INSTAGRAM = 'https://www.instagram.com/weareflexday';
export const FLEXDAY_LINKEDIN =
  'https://www.linkedin.com/company/flexdaysolutions';
export const POLICY_MAKER = 'https://policymaker.io/';
export const COOKIES = 'https://www.jll.co.uk/en/cookies';
export const PRIVACY_POLICY = 'https://www.jll.co.uk/en/privacy-statement';
export const TERMS_OF_USE = 'https://www.jll.co.uk/en/terms-of-service';
