/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import styled from '@emotion/styled';
import { Box, FormControl, Typography } from '@mui/material';

export const StyledFormControl = styled(FormControl)({
  width: '100%',
});

export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const DisplayText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const DropdownTextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
