/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  AccordionDetails,
  IconButton,
  Alert,
  Button,
} from '@mui/material';
import {
  List,
  Description,
  Delete,
  FindInPage,
  FileUpload,
  Save,
  Cancel,
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import FileSelector from '../../../components/fileSelector';
import PageContainer from '../../../components/pageContainer';
import AppTitle from '../../../components/app.title';
import AppShadowBox from '../../../components/app.shadowbox';
import DataGridTable from '../../../components/datagrid';
import {
  AccordionBox,
  AccordionGrid,
  BoxWrapper,
  ButtonGrid,
  ButtonLink,
  InputField,
  StyledAccordion,
  StyledAccordionSummary,
  StyledButtonGroup,
  StyledButtonLeft,
  StyledButtonRight,
} from './fileUploader.page.styled';
import {
  useAddEmptyCollectionMutation,
  useGetFileCollectionsQuery,
  useGetSearchEnginesQuery,
  useTestFileCollectionsMutation,
  useAddFilesToCollectionMutation,
} from '../../../redux/services/speciphicAsk';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_SIZE,
} from '../../../constants/files';
import { useTranslation } from 'react-i18next';
import * as PATHS from '../../../constants/path';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import DropdownOne from '../../../components/dropdownone';
import TestResultCard from './components/testResultCard/testResultCard.component';
import StorageConfiguration from './components/storageConfiguration';

const FileUploaderPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [files, setFiles] = useState([]);
  const [collectionName, setCollectionName] = useState('');
  const [searchEngineType, setSearchEngineType] = useState('');
  const [dataSource, setDataSource] = useState('');

  const [indexName, setIndexName] = useState('');
  const [expanded, setExpanded] = React.useState('panel');
  const [collectionNameValidation, setCollectionNameValidation] =
    useState(false);
  const [serviceValue, setServiceValue] = useState('0');

  const [
    addFilesToCollection,
    {
      data: Result,
      isSuccess: isGetResultsSuccess,
      isLoading: isGetResultsLoading,
      isError: isGetResultsError,
    },
  ] = useAddFilesToCollectionMutation();

  const [
    addEmptyCollection,
    {
      data: emptyResult,
      isSuccess: isAddEmptyCollectionSuccess,
      isLoading: isAddEmptyCollectionResultsLoading,
      isError: isAddEmptyCollectionError,
    },
  ] = useAddEmptyCollectionMutation();

  const [
    testCollection,
    {
      data: testResult,
      isSuccess: isTestResultsSuccess,
      isLoading: isTestResultsLoading,
      isError: isTestResultsError,
    },
  ] = useTestFileCollectionsMutation();

  const {
    data: FileCollections = [],
    isError: isGetFileCollectionsError,
    isLoading: isGetFileCollectionsLoading,
    isSuccess: isGetFileCollectionsSuccess,
  } = useGetFileCollectionsQuery({ include: ['name'] });

  const {
    data: searchEngines = [],
    isError: isGetSearchEnginesError,
    isLoading: isGetSearchEnginesLoading,
    isSuccess: isGetSearchEnginesSuccess,
  } = useGetSearchEnginesQuery();

  useEffect(() => {
    if (isGetResultsSuccess) {
      dispatch(
        pushToast({
          message: t('fileUploaderPage.alertMessages.fileUploadSuccess'),
          severity: 'success',
        }),
      );
    }
  }, [isGetFileCollectionsSuccess]);

  const handleTest = () => {
    testCollection({
      files: files,
    });
  };

  const handleFileCollection = () => {
    navigate(`${PATHS.SETTINGS_FILE_COLLECTIONS}`, {
      state: Result,
    });
  };

  const collectionNames = FileCollections.map((collection) => {
    return collection.name;
  });

  const handleAccordionExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handlePdfOpen = (fileName) => {
    const blobPdf = new Blob([fileName.row], {
      type: 'application/pdf',
    });
    blobPdf.name = fileName.row.name;
    let pdfUrl = window.URL.createObjectURL(blobPdf);
    window.open(pdfUrl);
  };

  const onDropForFiles = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const handleCollectionInputChange = (event) => {
    setCollectionName(event.target.value);
    handleGenerateInput(event.target.value);
    handleValidationCheck(event.target.value, serviceValue);
  };

  const handleGenerateInput = (name) => {
    var randomNumber = crypto.getRandomValues(new Uint8Array(1));
    setIndexName(
      name
        .trim()
        .replace(/\W+/g, '_')
        .concat(randomNumber)
        .replace(/\b_/, '')
        .toLowerCase(),
    );
  };

  const handleIndexInputChange = (event) => {
    setIndexName(event.target.value);
  };

  const removeFile = (e, filename) => {
    e.stopPropagation();
    if (files.length > 1) {
      setFiles(files.filter((file) => file.name !== filename));
    }
    if (files.length === 1) {
      setFiles(files.name !== filename);
    }
  };

  const handleAddFiles = (event) => {
    event.preventDefault();
    if (files.length > 0 && isAddEmptyCollectionSuccess) {
      addFilesToCollection({
        collectionId: emptyResult.id,
        files: files,
      });
    }
  };
  const handleAddEmptyCollection = (event) => {
    event.preventDefault();
    addEmptyCollection({
      name: collectionName,
      indexName: indexName,
      searchEngine: searchEngineType,
      dataSource: dataSource,
    });
  };

  const handleValidationCheck = (name) => {
    setCollectionNameValidation(() => collectionNames.includes(name.trim()));
  };

  const handleSearchEngine = (value) => {
    setSearchEngineType(value);
  };

  const handleClearFields = () => {
    setCollectionName('');
    setIndexName('');
  };

  const column = [
    {
      field: 'SN',
      headerName: 'Serial No.',
      sortable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.name) + 1,
    },
    {
      field: 'name',
      headerName: 'File name',
      minWidth: 300,
      flex: 1,
      renderCell: (props) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Description sx={{ marginRight: 1 }} />
            {props.row.name}
          </Box>
        );
      },
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (props) => {
        return (
          <IconButton
            color="primary"
            onClick={(e) => removeFile(e, props.row.name)}
          >
            <Delete />
          </IconButton>
        );
      },
    },
  ];

  return (
    <PageContainer>
      <BoxWrapper>
        <AppShadowBox>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h4" color={'primary'}>
                  {t('fileUploaderPage.title')}
                </Typography>
                <Typography variant="body1">
                  {t('fileUploaderPage.subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ justifyContent: 'flex-end' }}>
                <ButtonLink
                  size="medium"
                  variant="contained"
                  component={Link}
                  to={PATHS.SETTINGS}
                  startIcon={<List />}
                >
                  {t('fileUploaderPage.fileCollectionButton')}
                </ButtonLink>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  size="small"
                  fullWidth
                  required
                  error={collectionNameValidation}
                  id="outlined-required"
                  label={t('fileUploaderPage.fileCollection.textFieldLabel')}
                  onChange={handleCollectionInputChange}
                  helperText={
                    collectionNameValidation && (
                      <Alert variant="outlined" severity="error">
                        {t('fileUploaderPage.fileCollection.errorMessage')}
                      </Alert>
                    )
                  }
                  value={collectionName}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  size="small"
                  required
                  fullWidth
                  id="outlined-required"
                  label={t('fileUploaderPage.index.textFieldLabel')}
                  value={indexName}
                  onChange={handleIndexInputChange}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <DropdownOne
                  onChange={handleSearchEngine}
                  items={searchEngines}
                  value={searchEngineType}
                  label={t('fileUploaderPage.dropdown.searchEngineLabel')}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <StorageConfiguration onStorageChange={setDataSource} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ justifyContent: 'space-between' }}
              >
                <LoadingButton
                  size="medium"
                  variant="contained"
                  disabled={
                    indexName <= 0 ||
                    collectionName <= 0 ||
                    searchEngineType.length <= 0 ||
                    dataSource.length <= 0
                  }
                  onClick={handleAddEmptyCollection}
                  loading={isAddEmptyCollectionResultsLoading}
                  startIcon={<Save />}
                >
                  {t('fileUploaderPage.fileCollectionSave')}
                </LoadingButton>
                <Button
                  size="medium"
                  variant="contained"
                  disabled={indexName.length == 0 || collectionName.length == 0}
                  startIcon={<Cancel />}
                  onClick={handleClearFields}
                >
                  {t('fileUploaderPage.fileCollectionClear')}
                </Button>
              </Grid>
            </Grid>

            {isAddEmptyCollectionSuccess && (
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ margin: 1 }}>
                    {t('fileUploaderPage.dropzoneTitle')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FileSelector
                    label={t('fileUploaderPage.fileSelector.label')}
                    onChange={onDropForFiles}
                    fileTypes={ALLOWED_FILE_TYPES['text']}
                    maxFileSize={ALLOWED_FILE_SIZE}
                    numberOfFiles={files.length}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </AppShadowBox>
        <AccordionBox>
          {files.length > 0 && (
            <>
              <StyledAccordion
                expanded={expanded === 'panel'}
                onChange={handleAccordionExpand('panel')}
              >
                <StyledAccordionSummary>
                  <AccordionGrid
                    container
                    columnSpacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Grid item>
                      <Typography color={'primary'}>
                        {t('fileUploaderPage.onDrop', {
                          filesLength: files.length,
                        })}
                      </Typography>
                    </Grid>
                  </AccordionGrid>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <>
                    {files.length > 0 && (
                      <DataGridTable
                        row={files}
                        rowId={'name'}
                        column={column}
                        pageSize={5}
                        handleRow={handlePdfOpen}
                      />
                    )}
                    <ButtonGrid container>
                      <Grid item xs={12}>
                        <StyledButtonGroup>
                          <StyledButtonLeft
                            variant="outlined"
                            color="primary"
                            type="submit"
                            size="large"
                            disabled
                            loadingPosition="end"
                          >
                            <span>
                              {t('fileUploaderPage.buttonTexts.testButtonText')}
                            </span>
                          </StyledButtonLeft>
                          <StyledButtonRight
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                            disabled={files.length == 0}
                            onClick={handleAddFiles}
                            loading={isGetResultsLoading}
                            loadingPosition="end"
                          >
                            <span>
                              {t(
                                'fileUploaderPage.buttonTexts.uploadButtonText',
                              )}
                            </span>
                          </StyledButtonRight>
                        </StyledButtonGroup>
                      </Grid>
                    </ButtonGrid>
                  </>
                </AccordionDetails>
              </StyledAccordion>
            </>
          )}
        </AccordionBox>
        {isGetResultsSuccess && handleFileCollection()}
        {isTestResultsSuccess && <TestResultCard testResult={testResult} />}
      </BoxWrapper>
    </PageContainer>
  );
};

FileUploaderPage.propTypes = {
  row: PropTypes.array,
};
export default FileUploaderPage;
