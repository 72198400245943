/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Tooltip,
  Icon,
  Dialog,
  DialogContent,
  Alert,
  AlertTitle,
  ButtonGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteForever, FileOpen, Security } from '@mui/icons-material';
import CustomToolbar from './fileColletionDataGridCustomToolbar.component';
import { ReactComponent as FileCollectionIcon } from '../../../../../assets/file-collection-icon.svg';
import {
  StyledDataGrid,
  StyledSearchIcon,
  StyledClearIcon,
  StyledFileWrapper,
  DialogActionsWrapper,
} from './fileCollectionsDataGrid.styled';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  useDeleteCollectionsMutation,
  useGetDataSourceQuery,
  useGetSearchEnginesQuery,
} from '../../../../../redux/services/speciphicAsk';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../../../redux/reducers/toasts.slice';
import { useNavigate } from 'react-router-dom';
import * as PATHS from '../../../../../constants/path';

const FileCollectionDataGrid = ({ fileCollections }) => {
  const [fileCollectionsIdDelete, setFileCollectionsIdDelete] = useState('');
  const [openDeleteFile, setOpenDeleteFile] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: searchEngines = [],
    isError: isGetSearchEngineError,
    isLoading: isGetSearchEngineLoading,
    isSuccess: isGetSearchEngineErrorSuccess,
  } = useGetSearchEnginesQuery();

  const {
    data: dataSources = [],
    isError: isGetDataSourceError,
    isLoading: isGetDataSourceLoading,
    isSuccess: isGetDataSourceSuccess,
  } = useGetDataSourceQuery();

  const [
    deleteCollection,
    {
      isError: isDeleteFileCollectionsError,
      isLoading: isDeleteFileCollectionsLoading,
      isSuccess: isDeleteFileCollectionsSuccess,
    },
  ] = useDeleteCollectionsMutation();

  const handleDeleteFile = (fileCollectionsIdDelete) => {
    setOpenDeleteFile(false);
    deleteCollection({
      collectionId: fileCollectionsIdDelete,
    });
  };

  const openDeleteFileDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteFile(true);
    setFileCollectionsIdDelete(row.id);
  };

  const closeDelete = () => {
    setOpenDeleteFile(false);
  };

  useEffect(() => {
    if (isDeleteFileCollectionsError) {
      dispatch(
        pushToast({
          message: t('fileCollectionsPage.alertMessages.deleteFileAlert'),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteFileCollectionsError]);

  const getNumberOfFiles = (props) => {
    return `${props.row.files?.length || '0'}`;
  };

  const getDates = (props) => {
    return `${props.row.createdAt.slice(0, 11)}`;
  };
  const getNames = (props) => {
    let name = props.row.createdBy?.split('@')[0];
    return name
      ? `${name.replace('.', ' ')} `
      : t('fileCollectionsPage.dataGrid.notFound');
  };

  const handleRowClick = (row) => {
    navigate(`${PATHS.SETTINGS_FILE_COLLECTIONS}/${row.id}`, {
      state: row,
    });
  };

  const fileCollectionNameColumn = (props) => {
    return (
      <StyledFileWrapper>
        <Icon component={FileCollectionIcon} sx={{ marginRight: 1 }} />
        {props.row.name}
        <br />
        {searchEngines?.find(
          (searchEngine) => searchEngine.value === props.row.searchEngine,
        )?.label || t('fileCollectionsPage.dataGrid.notFound')}
      </StyledFileWrapper>
    );
  };

  const fileCollectionDataSourceColumn = (props) => {
    return (
      <StyledFileWrapper>
        {dataSources?.find(
          (dataSource) => dataSource.id === props.row.storageId,
        )?.connectionName || t('fileCollectionsPage.dataGrid.notFound')}
      </StyledFileWrapper>
    );
  };

  const actionColumn = (props) => {
    return (
      <>
        <ButtonGroup>
          <Tooltip title={t('fileCollectionsPage.columnAction.openFile')} arrow>
            <LoadingButton onClick={() => handleRowClick(props.row)}>
              <span>
                <FileOpen />
              </span>
            </LoadingButton>
          </Tooltip>
          <Tooltip
            title={t('fileCollectionsPage.columnAction.deleteFile')}
            arrow
          >
            <LoadingButton
              onClick={(e) => openDeleteFileDialog(e, props.row)}
              loading={
                props.row.id == fileCollectionsIdDelete &&
                isDeleteFileCollectionsLoading
              }
            >
              <span>
                <DeleteForever />
              </span>
            </LoadingButton>
          </Tooltip>
          <Tooltip title={t('fileCollectionsPage.columnAction.policy')} arrow>
            <LoadingButton onClick={(e) => e.stopPropagation()}>
              <span>
                <Security />
              </span>
            </LoadingButton>
          </Tooltip>
        </ButtonGroup>
      </>
    );
  };

  const columnsForDesktop = [
    {
      field: 'name',
      headerName: t('fileCollectionsPage.dataGrid.columns.fileCollectionName'),
      width: 300,
      renderCell: fileCollectionNameColumn,
    },
    {
      field: 'files',
      headerName: t('fileCollectionsPage.dataGrid.columns.noOfFiles'),
      width: 150,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      valueGetter: getNumberOfFiles,
    },
    {
      field: 'dataSource',
      headerName: t('fileCollectionsPage.dataGrid.columns.dataSource'),
      width: 250,
      renderCell: fileCollectionDataSourceColumn,
    },
    {
      field: 'storeIndexName',
      headerName: t('fileCollectionsPage.dataGrid.columns.index'),
      minWidth: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: 'createdBy',
      headerName: t('fileCollectionsPage.dataGrid.columns.createdBy'),
      width: 150,
      valueGetter: getNames,
    },
    {
      field: 'createdAt',
      headerName: t('fileCollectionsPage.dataGrid.columns.createdOn'),
      width: 150,
      valueGetter: getDates,
    },

    {
      field: 'actions',
      headerName: t('fileCollectionsPage.dataGrid.columns.actions'),
      width: 120,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  return (
    <Box>
      <StyledDataGrid
        sx={{
          borderColor: 'white',
        }}
        autoHeight
        pageSize={10}
        rows={fileCollections}
        columns={columnsForDesktop}
        disableSelectionOnClick
        disableColumnMenu
        onRowClick={(props) => handleRowClick(props.row)}
        components={{
          Toolbar: CustomToolbar,
          QuickFilterIcon: StyledSearchIcon,
          QuickFilterClearIcon: StyledClearIcon,
        }}
      />
      <Dialog open={openDeleteFile}>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>
              {t('fileCollectionsPage.alertDelete.delete')}
            </AlertTitle>
            <AlertTitle>
              <strong>
                {t('fileCollectionsPage.alertDelete.deleteFileCaption')}
              </strong>
            </AlertTitle>
          </Alert>
        </DialogContent>
        <DialogActionsWrapper>
          <Button variant="outlined" size="small" onClick={closeDelete}>
            {t('fileCollectionsPage.buttonText.cancel')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDeleteFile(fileCollectionsIdDelete)}
          >
            {t('fileCollectionsPage.buttonText.delete')}
          </Button>
        </DialogActionsWrapper>
      </Dialog>
    </Box>
  );
};

FileCollectionDataGrid.propTypes = {
  row: PropTypes.object,
  fileCollections: PropTypes.array,
};

export default FileCollectionDataGrid;
