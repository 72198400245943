/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import blueTheme from './blue/theme';
import greenTheme from './green/theme';
import purpleTheme from './purple/theme';
import orangeTheme from './orange/theme';
import redTheme from './red/theme';
import tropicanaTheme from './tropicana/theme';
import jllTheme from './jll/theme';

function getTheme(themeName) {
  switch (themeName) {
    case 'blue':
      return blueTheme;
    case 'red':
      return redTheme;
    case 'orange':
      return orangeTheme;
    case 'purple':
      return purpleTheme;
    case 'green':
      return greenTheme;
    case 'tropicana':
      return tropicanaTheme;
    case 'jll':
      return jllTheme;
    default:
      return tropicanaTheme;
  }
}

export default getTheme;
