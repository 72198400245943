/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, Box } from '@mui/material';
import SvgBackground from '../../assets/onboardingLeftbgSvg';
import { renderToStaticMarkup } from 'react-dom/server';

const SvgStringBackground = encodeURIComponent(
  renderToStaticMarkup(<SvgBackground />),
);

export const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  minHeight: '100%',
  backgroundImage: `url("data:image/svg+xml,${SvgStringBackground}")` /* Specifically for JLL theme */,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  backgroundPositionY: 'bottom',
  backgroundSize: '30%',
}));
