/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Typography, Grid, Link, Box, Button } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Thumbsup } from '../../assets/feedback-thumbs-up.svg';
import { ReactComponent as ThumbsDown } from '../../assets/feedback-thumbs-down.svg';
import { ReactComponent as ShareIcon } from '../../assets/answer-share-icon.svg';
import {
  CardContentBox,
  ContentBox,
  StyleShareTypography,
  StyledAnswerTypography,
  StyledCard,
  StyledIconButton,
  StyledPdfBox,
  StyledPdfContiner,
  StyledStack,
  FeedBackWrapper,
  AnswerComponentBottomWrapper,
  ShareWrapper,
  AnsWrapper,
} from './answer.styled';
import PdfPreview from '../../pages/searchPage/pdfPreview';
import DialogBox from '../../pages/searchPage/pdfDialogBox';
import { SEARCH_VIEW_PDF_NO } from '../../constants/queryAnswer';

const AnswerWithPdf = ({
  title,
  description,
  metas,
  pdfPreview,
  feedback,
  result,
  query,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [hoverForThumbsup, setHoverForThumbsup] = useState(false);
  const [hoverForThumbsDown, setHoverForThumbsDown] = useState(false);
  const [shareIconColor, setShareIconColor] = useState('secondary');
  const [meta, setMeta] = useState(null);

  const subjectOfMail = t('answer.emailSubject', {
    query: query,
  });

  const bodyOfMail = `${title}%0D%0DYou can find the answer in %0D${
    metas &&
    metas
      .map((meta) => {
        return `"${meta.originalFileName}" link to the file "${meta?.url}" page number ${meta.page} %0D`;
      })
      .join('\n%0D')
  }`;

  const handleDialogClickOpen = (meta) => {
    setMeta(meta);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleFeedbackSend = (bool) => {
    bool
      ? setHoverForThumbsup(true) & setHoverForThumbsDown(false)
      : setHoverForThumbsup(false) & setHoverForThumbsDown(true);
    feedback(result, bool);
  };

  return (
    <>
      <AnsWrapper>
        <ContentBox>
          <StyledAnswerTypography>{title}</StyledAnswerTypography>
        </ContentBox>
        <ContentBox>
          <Typography variant="body1">{description}</Typography>
          {pdfPreview && (
            <>
              <Grid container>
                {metas &&
                  metas.map(
                    (meta, index) =>
                      index < SEARCH_VIEW_PDF_NO && (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          justifyContent={'center'}
                          display={'flex'}
                        >
                          <StyledPdfContiner>
                            <StyledPdfBox
                              onClick={() => handleDialogClickOpen(meta)}
                            >
                              <PdfPreview
                                pageNumber={meta.page}
                                pdfUrl={`${meta?.url}`}
                                scale={0.3}
                              />
                            </StyledPdfBox>
                            <Typography
                              sx={{
                                marginTop: 1,
                                width: '184px',
                                fontWeight: '300',
                                wordBreak: 'break-all',
                                fontSize: '16px',
                                color: '#131e29',
                                textTransform: 'lowercase',
                                lineHeight: '22px',
                              }}
                              variant="subtitle1"
                              color={(theme) => theme.palette.text.caption}
                            >
                              <Link
                                href={`${meta?.url}`}
                                target="_blank"
                                underline="none"
                                color="inherit"
                              >
                                {meta.fileName}
                              </Link>
                            </Typography>
                          </StyledPdfContiner>
                        </Grid>
                      ),
                  )}
              </Grid>
            </>
          )}
          <AnswerComponentBottomWrapper>
            <FeedBackWrapper>
              <Box>
                <Typography sx={{ fontSize: '18px', fontWeight: 300 }}>
                  {t('answer.feedbackText')}
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={() => handleFeedbackSend(true)}
                sx={{ marginRight: '10px', marginLeft: '10px' }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleFeedbackSend(false)}
              >
                No
              </Button>
            </FeedBackWrapper>
            <ShareWrapper>
              <Link
                href={`mailto:?subject=${subjectOfMail}&body=${bodyOfMail}`}
                target="_blank"
                rel="noreferrer"
                underline="none"
              >
                <StyledIconButton
                  color={shareIconColor}
                  size="small"
                  sx={{ borderRadius: 0 }}
                  onClick={() => setShareIconColor('primary')}
                >
                  <ShareIcon
                    alt={t(
                      'questionAnswerPage.answerFeedback.thumbsDownImgAlt',
                    )}
                  />
                  <StyleShareTypography variant="subtitle1">
                    {t('answer.shareButton')}
                  </StyleShareTypography>
                </StyledIconButton>
              </Link>
            </ShareWrapper>
          </AnswerComponentBottomWrapper>
        </ContentBox>
      </AnsWrapper>
      {meta && (
        <DialogBox
          open={open}
          url={`${meta?.url}`}
          file={meta.originalFileName}
          handleClose={handleDialogClose}
          pageNumber={meta.page}
        />
      )}
    </>
  );
};

AnswerWithPdf.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  metas: PropTypes.array,
  fileName: PropTypes.string,
  pdfPreview: PropTypes.bool,
  feedback: PropTypes.func,
  result: PropTypes.object,
  query: PropTypes.string,
};

export default AnswerWithPdf;
