/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Typography, styled } from '@mui/material';

export const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const ParagraphText = styled(Typography)(() => ({
  marginTop: 2,
  marginBottom: 2,
  padding: 0,
}));

export const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(0.5),
}));
