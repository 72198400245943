import { Box, Button, Dialog, DialogTitle, styled } from '@mui/material';

export const StyledCancelButton = styled(Button)({
  position: 'absolute',
  top: 0,
  right: 0,
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

export const StyledDialogTitle = styled(DialogTitle)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const PdfBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'auto',
});
