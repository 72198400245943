/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import { useEffect } from 'react';
import { getUserFromSession } from '../../redux/user';
import { AUTHORITY, CLIENT } from '../../constants/auth';
const SESSION_OIDC_TOKEN_KEY = 'oidc.user';

const BotpressChatbot = () => {
  const existingScript = document.getElementById('bp-web-widget');
  if (!existingScript) {
    useEffect(() => {
      const script = document.createElement('script');
      const user = {
        user_profile: getUserFromSession()?.profile.name,
        oidc_User: sessionStorage.getItem(
          `${SESSION_OIDC_TOKEN_KEY}:${AUTHORITY}:${CLIENT}`,
        ),
      };
      var my_data = JSON.stringify(user);
      script.src =
        'https://dev.bot.ask.speciphic.ai/assets/modules/channel-web/inject.js';
      script.addEventListener('load', () => {
        try {
          let config = {
            host: 'https://dev.bot.ask.speciphic.ai',
            botId: 'specificask',
            exposeStore: false,
            startNewConvoOnTimeout: true,
            useSessionStorage: true,
            enableTranscriptDownload: false,
            composerPlaceholder: 'Reply to Speciphic™Ask',
            enableConversationDeletion: true,
            disableNotificationSound: true,
            extraStylesheet:
              'https://dev.bot.ask.speciphic.ai/assets/modules/channel-web/flexstyle.css',
          };

          window.botpressWebChat.init(config);
          window.addEventListener('message', function (event) {
            if (event.data.name && event.data.name == 'webchatReady') {
              window.botpressWebChat.sendEvent({
                type: 'proactive-trigger',
                channel: 'web',
                payload: { text: my_data },
              });
            } else if (
              event.data.payload &&
              event.data.payload.type == 'session_reset'
            ) {
              window.botpressWebChat.sendEvent({
                type: 'proactive-trigger',
                channel: 'web',
                payload: { text: my_data },
              });
            }
          });
        } catch (e) {
          console.log('error in Botpress.jsx', e);
        }
      });
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);
    return <></>;
  }
};

export default BotpressChatbot;
