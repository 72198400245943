/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import { createContext, React, useState } from 'react';
import PropTypes from 'prop-types';

const FileCollectionContext = createContext();

const FileCollectionProvider = (props) => {
  const [collectionId, setCollectionId] = useState('');

  const updateCollectionId = (value, input) => {
    setCollectionId(value);
  };

  const getCollectionId = () => {
    return collectionId;
  };

  return (
    <FileCollectionContext.Provider
      value={{ getCollectionId, updateCollectionId }}
    >
      {props.children}
    </FileCollectionContext.Provider>
  );
};
FileCollectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FileCollectionContext, FileCollectionProvider };
