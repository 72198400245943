import React, { useCallback, useEffect, useState } from 'react';
import AppShadowBox from '../../../components/app.shadowbox';
import AppTitle from '../../../components/app.title';
import PageContainer from '../../../components/pageContainer';
import { useTranslation } from 'react-i18next';
import {
  AlertTitle,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Delete, Create, List } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useDeleteStoreMutation,
  useGetIndividualFileCollectionQuery,
  useStoreFileCollectionsMutation,
  useDeleteIndividualFileMutation,
  useGetSearchEnginesQuery,
  useGetDataSourceQuery,
  useAddFilesToCollectionMutation,
} from '../../../redux/services/speciphicAsk';
import { useDropzone } from 'react-dropzone';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import DataGridTable from '../../../components/datagrid/datagrid.component';
import {
  AppTitleBox,
  FilesAddedAlert,
  FileCollectionButton,
  AddFilesButton,
} from './individualFileCollection.styled';
import PropTypes from 'prop-types';
import { Add } from '@mui/icons-material';
import * as PATHS from '../../../constants/path';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_SIZE,
} from '../../../constants/files';

const IndividualFileCollectionPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [filesAdded, setFilesAdded] = useState(false);
  const [singleFile, setSingleFile] = useState('');
  const [fileCollection, setFileCollection] = useState([]);
  const query = { collectionId: state.id };

  const {
    data: searchEngines = [],
    isError: isGetSearchEngineError,
    isLoading: isGetSearchEngineLoading,
    isSuccess: isGetSearchEngineErrorSuccess,
  } = useGetSearchEnginesQuery();

  const {
    data: dataSources = [],
    isError: isGetDataSourceError,
    isLoading: isGetDataSourceLoading,
    isSuccess: isGetDataSourceSuccess,
  } = useGetDataSourceQuery();

  const [
    addFilesToCollection,
    {
      data: updateResult,
      isSuccess: isUpdateResultSuccess,
      isLoading: isUpdateResultLoading,
      isError: isUpdateResultError,
    },
  ] = useAddFilesToCollectionMutation();

  const [
    storeCollection,
    {
      data: storeResult,
      isSuccess: isStoreResultsSuccess,
      isLoading: isStoreResultsLoading,
      isError: isStoreResultsError,
    },
  ] = useStoreFileCollectionsMutation();

  const [
    deleteCollectionStore,
    {
      data: deleteStore,
      isSuccess: isDeleteStoreSuccess,
      isLoading: isDeleteStoreLoading,
      isError: isDeleteStoreError,
    },
  ] = useDeleteStoreMutation();

  const [
    deleteIndividualFile,
    {
      data: deleteFile,
      isSuccess: isDeleteFileSuccess,
      isLoading: isDeleteFileLoading,
      isError: isDeleteFileError,
    },
  ] = useDeleteIndividualFileMutation();

  const {
    data: individualFileCollection,
    isError: isGetIndividualFileCollectionError,
    isLoading: isGetIndividualFileCollectionLoading,
    isSuccess: isGetIndividualFileCollectionSuccess,
  } = useGetIndividualFileCollectionQuery(query);

  useEffect(() => {
    if (individualFileCollection !== undefined) {
      handleAddData();
    }
  }, [isGetIndividualFileCollectionSuccess, individualFileCollection]);

  const handleAddData = () => {
    //if originalFileName field not already present, add a field for it.
    if (
      individualFileCollection.files?.every(
        (file) => file.originalFileName !== undefined,
      )
    ) {
      //directly serialise it
      handleSerialiseCollection(individualFileCollection.files);
    } else {
      //add a field for originalFileName and then serialise:
      handleSerialiseCollection(
        individualFileCollection.files?.map((file) => {
          return { originalFileName: file };
        }),
      );
    }
  };

  const handleSerialiseCollection = (arr) => {
    let sNo = 1;
    const serializedArr = arr?.reduce((collection, file) => {
      const sNoAdded = { ...file, sNo: sNo };
      collection.push(sNoAdded);
      sNo++;
      return collection;
    }, []);
    setFileCollection(serializedArr);
  };

  useEffect(() => {
    if (isStoreResultsSuccess) {
      dispatch(
        pushToast({
          message: t(
            'individualFileCollectionPage.alertMessages.storeUploadSuccess',
          ),
          severity: 'success',
        }),
      );
    }
  }, [isStoreResultsSuccess]);

  useEffect(() => {
    if (isUpdateResultSuccess) {
      dispatch(
        pushToast({
          message: t(
            'individualFileCollectionPage.alertMessages.updateResultSuccess',
          ),
          severity: 'success',
        }),
      );
    }
  }, [isUpdateResultSuccess]);

  useEffect(() => {
    if (isDeleteStoreSuccess) {
      dispatch(
        pushToast({
          message: t(
            'individualFileCollectionPage.alertMessages.storeDeleteSuccess',
          ),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteStoreSuccess]);

  useEffect(() => {
    if (isDeleteFileSuccess) {
      dispatch(
        pushToast({
          message: t(
            'individualFileCollectionPage.alertMessages.fileDeleteSuccess',
          ),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteFileSuccess]);

  const onFileDrop = useCallback((acceptedFiles) => {
    setNewFiles(acceptedFiles);
    setFilesAdded(true);
    setOpen(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileDrop,
    accept: ALLOWED_FILE_TYPES['text'],
    maxSize: ALLOWED_FILE_SIZE,
  });

  const handleStore = () => {
    storeCollection({
      collectionId: state.id,
    });
  };

  const handleDeleteStore = () => {
    deleteCollectionStore({
      collectionId: state.id,
    });
  };

  const handleDeleteFile = (e, file) => {
    e.stopPropagation();
    deleteIndividualFile({
      collectionId: state.id,
      originalFileName: file,
    });
    if (individualFileCollection.files?.length == 1) {
      navigate(PATHS.SETTINGS_FILE_COLLECTIONS);
    }
  };

  const handleCancelClick = () => {
    setNewFiles([]);
    setOpen(false);
  };

  const handleUploadFilesClick = () => {
    setOpen(false);
    if (filesAdded) {
      addFilesToCollection({
        collectionId: state.id,
        files: newFiles,
      });
    }
  };

  const column = [
    {
      field: 'sNo',
      headerName: t('individualFileCollectionPage.labels.sNo'),
      sortable: false,
    },
    {
      field: 'originalFileName',
      headerName: t('individualFileCollectionPage.labels.fileName'),
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'Delete',
      headerName: t('individualFileCollectionPage.labels.deleteFile'),
      sortable: false,
      width: 150,
      headerAlign: 'right',
      align: 'right',
      renderCell: (props) => {
        return (
          <>
            <ButtonGroup>
              <LoadingButton
                color="primary"
                loading={
                  isDeleteFileLoading &&
                  props.row.originalFileName == singleFile
                }
                onClick={(e) => handleDeleteFile(e, props.row.originalFileName)}
              >
                <DeleteIcon />
              </LoadingButton>
            </ButtonGroup>
          </>
        );
      },
    },
  ];

  return (
    <PageContainer>
      <Dialog open={open}>
        <DialogContent>
          <FilesAddedAlert severity="success">
            <AlertTitle>
              {t('individualFileCollectionPage.AlertText', {
                newFiles: newFiles.length,
              })}
            </AlertTitle>
          </FilesAddedAlert>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            padding: 3,
            justifyContent: 'space-between',
          }}
        >
          <LoadingButton
            variant="outlined"
            size="small"
            onClick={handleCancelClick}
          >
            {t('individualFileCollectionPage.buttonTexts.cancelButton')}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="small"
            onClick={handleUploadFilesClick}
          >
            {t('individualFileCollectionPage.buttonTexts.addButton')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <AppTitleBox>
        <AppTitle
          heading={t('individualFileCollectionPage.appTitle.heading')}
          subtitle={t('individualFileCollectionPage.appTitle.subtitle')}
        />
      </AppTitleBox>
      <AppShadowBox>
        {isGetIndividualFileCollectionLoading && (
          <>
            <LinearProgress />
            <Typography variant="h5">
              {t('individualFileCollectionPage.pageLoading')}
            </Typography>
          </>
        )}
        {isGetIndividualFileCollectionSuccess && (
          <>
            <Grid
              sx={{
                paddingBottom: 2,
              }}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <FileCollectionButton
                sx={{ float: 'right' }}
                size="medium"
                variant="contained"
                startIcon={<List />}
                component={Link}
                to={PATHS.SETTINGS}
              >
                {t('individualFileCollectionPage.buttonTexts.fileCollections')}
              </FileCollectionButton>
            </Grid>
            <Divider />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              <Grid item xs={12} sm={6} padding={1} display={'flex'}>
                <Grid item xs={6}>
                  <Typography>
                    {t('individualFileCollectionPage.labels.collectionName')}{' '}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>
                      {individualFileCollection.name
                        ? individualFileCollection.name
                        : t('individualFileCollectionPage.notFound')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} padding={1} display={'flex'}>
                <Grid item xs={6}>
                  <Typography>
                    {t('individualFileCollectionPage.labels.indexName')}{' '}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>
                      {individualFileCollection.storeIndexName
                        ? individualFileCollection.storeIndexName
                        : t('individualFileCollectionPage.notFound')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} sm={6} padding={1} display={'flex'}>
                <Grid item xs={6}>
                  <Typography>
                    {t('individualFileCollectionPage.labels.createdBy')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>
                      {individualFileCollection.createdBy
                        ? individualFileCollection.createdBy
                            .split('@')[0]
                            .replace('.', ' ')
                        : t('individualFileCollectionPage.notFound')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} padding={1} display={'flex'}>
                <Grid item xs={6}>
                  <Typography>
                    {t('individualFileCollectionPage.labels.createdOn')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>
                      {individualFileCollection.createdAt
                        ? individualFileCollection.createdAt.slice(0, 11)
                        : t('individualFileCollectionPage.notFound')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} padding={1} display={'flex'}>
                <Grid item xs={6}>
                  <Typography>
                    {t('individualFileCollectionPage.labels.lastUpdatedBy')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>
                      {individualFileCollection.lastUpdatedBy
                        ? individualFileCollection.lastUpdatedBy
                            .split('@')[0]
                            .replace('.', ' ')
                        : t('individualFileCollectionPage.notFound')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} padding={1} display={'flex'}>
                <Grid item xs={6}>
                  <Typography>
                    {t('individualFileCollectionPage.labels.numberOfFiles')}
                  </Typography>
                </Grid>
                <Grid item xs={6} display={'flex'}>
                  <Typography>
                    <b>
                      {individualFileCollection.files?.length ||
                        t('individualFileCollectionPage.notFound')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} padding={1} display={'flex'}>
                <Grid item xs={6}>
                  <Typography>
                    {t('individualFileCollectionPage.labels.dataSource')}
                  </Typography>
                </Grid>
                <Grid item xs={6} display={'flex'}>
                  <Typography>
                    <b>
                      {dataSources?.find(
                        (dataSource) =>
                          dataSource.id === individualFileCollection.storageId,
                      )?.connectionName ||
                        t('individualFileCollectionPage.notFound')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} padding={1} display={'flex'}>
                <Grid item xs={6}>
                  <Typography>
                    {t('individualFileCollectionPage.labels.serachEngine')}
                  </Typography>
                </Grid>
                <Grid item xs={6} display={'flex'}>
                  <Typography>
                    <b>
                      {searchEngines?.find(
                        (searchEngine) =>
                          searchEngine.value ===
                          individualFileCollection.searchEngine,
                      )?.label || t('individualFileCollectionPage.notFound')}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* {!individualFileCollection.isStoreCreated ?? (
              <Alert severity="warning">
                {t('individualFileCollectionPage.alertMessage.storeAlert')}
              </Alert>
            )} */}

            <Grid container spacing={1}>
              <Grid
                container
                item
                spacing={1}
                marginBottom={1}
                justifyContent={'space-between'}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={'auto'}
                  justifyContent={'flex-end'}
                >
                  {individualFileCollection.isStoreCreated ? (
                    // <LoadingButton
                    //   variant="outlined"
                    //   onClick={handleStore}
                    //   loading={isStoreResultsLoading}
                    //   loadingPosition="end"
                    //   startIcon={<Create />}
                    //   fullWidth
                    // >
                    //   {t(
                    //     'individualFileCollectionPage.buttonTexts.createStore',
                    //   )}
                    // </LoadingButton>
                    <Alert severity="success">
                      {t(
                        'individualFileCollectionPage.alertMessages.storeCreated',
                      )}
                    </Alert>
                  ) : (
                    // <LoadingButton
                    //   variant="outlined"
                    //   onClick={handleDeleteStore}
                    //   loading={isDeleteStoreLoading}
                    //   loadingPosition="end"
                    //   startIcon={<Delete />}
                    //   fullWidth
                    // >
                    //   {t(
                    //     'individualFileCollectionPage.buttonTexts.deleteStore',
                    //   )}
                    // </LoadingButton>
                    <Alert severity="error">
                      {t(
                        'individualFileCollectionPage.alertMessages.storeNotCreated',
                      )}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={'auto'}>
                  <AddFilesButton
                    size="medium"
                    variant="contained"
                    loading={isUpdateResultLoading}
                    loadingPosition="end"
                    isDragActive={isDragActive}
                    {...getRootProps()}
                    startIcon={<Add />}
                    fullWidth
                  >
                    {isUpdateResultLoading
                      ? t(
                          'individualFileCollectionPage.buttonTexts.filesAddedButton',
                          {
                            newFilesLength: newFiles.length,
                          },
                        )
                      : t('individualFileCollectionPage.buttonTexts.addButton')}
                    <input {...getInputProps()} />
                  </AddFilesButton>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {fileCollection && (
                  <DataGridTable
                    row={fileCollection}
                    rowId={'sNo'}
                    column={column}
                    pageSize={5}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </AppShadowBox>
    </PageContainer>
  );
};
IndividualFileCollectionPage.propTypes = {
  row: PropTypes.array,
};

export default IndividualFileCollectionPage;
