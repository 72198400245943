/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useEffect, useRef } from 'react';
import { PageWrapper } from './pageContainer.styled';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

const PageContainer = (props) => {
  const ref = useRef(null);
  const path = useLocation();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [path.pathname]);

  return <PageWrapper ref={ref}>{props.children}</PageWrapper>;
};

PageContainer.propTypes = {
  children: PropTypes.node,
};
export default PageContainer;
