/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './app.css';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux';
import App from './app';

import './i18n';
import {
  AUTHORITY,
  CLIENT,
  REDIRECT_URL,
  LOGOUT_REDIRECT_URL,
  SCOPE,
} from './constants/auth';
const root = ReactDOM.createRoot(document.getElementById('root'));

const oidcConfig = {
  authority: AUTHORITY,
  client_id: CLIENT,
  scope: SCOPE,
  redirect_uri: REDIRECT_URL,
  post_logout_redirect_uri: LOGOUT_REDIRECT_URL,
};

const handleSignin = (state) => {
  if (state?.state !== undefined) {
    window.location.replace(atob(state.state));
  } else {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
};

root.render(
  <Provider store={store}>
    <AuthProvider {...oidcConfig} onSigninCallback={handleSignin}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
