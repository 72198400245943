import React from 'react';

export default function OnboardingBackgroundRightSvg() {
  return (
    <svg
      width="666"
      height="258"
      viewBox="0 0 666 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18_3615)">
        <path
          d="M536.8 2C634.8 2 714.6 160.5 714.6 355.4C714.6 550.3 634.8 708.8 536.8 708.8C438.8 708.8 359 550.2 359 355.4C359 160.6 438.8 2 536.8 2ZM536.8 0C437.5 0 357 159.1 357 355.4C357 551.7 437.5 710.8 536.8 710.8C636.1 710.8 716.6 551.7 716.6 355.4C716.6 159.1 636.1 0 536.8 0Z"
          fill="url(#paint0_linear_18_3615)"
        />
        <path
          d="M417.6 2C515.6 2 595.4 160.5 595.4 355.4C595.4 550.3 515.6 708.8 417.6 708.8C319.6 708.8 239.8 550.2 239.8 355.4C239.8 160.6 319.6 2 417.6 2ZM417.6 0C318.3 0 237.8 159.1 237.8 355.4C237.8 551.7 318.3 710.8 417.6 710.8C516.9 710.8 597.4 551.7 597.4 355.4C597.4 159.1 516.9 0 417.6 0Z"
          fill="url(#paint1_linear_18_3615)"
        />
        <path
          d="M298.4 2C396.4 2 476.2 160.5 476.2 355.4C476.2 550.3 396.4 708.8 298.4 708.8C200.4 708.8 120.6 550.2 120.6 355.4C120.6 160.6 200.4 2 298.4 2ZM298.4 0C199.1 0 118.6 159.1 118.6 355.4C118.6 551.7 199.1 710.8 298.4 710.8C397.7 710.8 478.2 551.7 478.2 355.4C478.2 159.1 397.7 0 298.4 0Z"
          fill="url(#paint2_linear_18_3615)"
        />
        <path
          d="M179.2 2C277.3 2 357 160.5 357 355.4C357 550.3 277.2 708.8 179.2 708.8C81.2 708.8 1.4 550.2 1.4 355.4C1.4 160.6 81.2 2 179.2 2ZM179.2 0C79.9 0 -0.599998 159.1 -0.599998 355.4C-0.599998 551.7 79.9 710.8 179.2 710.8C278.5 710.8 359 551.7 359 355.4C359 159.1 278.5 0 179.2 0Z"
          fill="url(#paint3_linear_18_3615)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_18_3615"
          x1="716.61"
          y1="355.39"
          x2="356.99"
          y2="355.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003E51" />
          <stop offset="1" stopColor="#003E51" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18_3615"
          x1="597.43"
          y1="355.39"
          x2="237.81"
          y2="355.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stopColor="#003E51" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_18_3615"
          x1="478.24"
          y1="355.39"
          x2="118.62"
          y2="355.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stopColor="#003E51" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_18_3615"
          x1="359.05"
          y1="355.39"
          x2="-0.569989"
          y2="355.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stopColor="#003E51" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_18_3615">
          <rect width="666" height="258" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
