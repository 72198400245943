// use moment.js for this

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

const formatDate = (date) => {
  return (
    months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
  );
};

const readableDate = (date) => {
  const d = new Date(date);
  return formatDate(d) + ', ' + formatTime(d);
};

export default readableDate;
