/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, TableCell, Typography, styled } from '@mui/material';

export const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(0.5),
}));

export const BoxWrap = styled(Box)(({ theme }) => ({
  display: 'grid',
  justifyItems: 'center',
  padding: theme.spacing(4),
}));

export const BorderedCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
}));
