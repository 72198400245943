/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Grid } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import {
  StyledToolbarGrid,
  StyledFileUploadButton,
} from './fileCollectionsDataGrid.styled';
import * as PATHS from '../../../../../constants/path';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const CustomToolbar = () => {
  const { t } = useTranslation();
  return (
    <StyledToolbarGrid container>
      <Grid item xs={12} sm={4} md={'auto'}>
        <GridToolbarQuickFilter />
      </Grid>
      <Grid item xs={12} sm={4} md={'auto'}>
        <StyledFileUploadButton
          variant="contained"
          size="medium"
          component={Link}
          to={PATHS.SETTINGS_FILE_UPLOAD}
          startIcon={<Add />}
        >
          {t('fileCollectionsPage.buttonText.create')}
        </StyledFileUploadButton>
      </Grid>
    </StyledToolbarGrid>
  );
};

export default CustomToolbar;
