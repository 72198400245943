/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LinkText from '../../components/linkText';
import PageContainer from '../../components/pageContainer';
import { HeadingText, ParagraphText, TitleText } from './cookie.page.styled';
import * as PATHS from '../../constants/path';

const CookiePage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <TitleText variant="h2">{t('cookiePage.title')}</TitleText>
      <ParagraphText variant="body1">
        <Trans
          i18nKey="cookiePage.introduction"
          components={{
            weblink: (
              <LinkText
                to={PATHS.FLEXDAY_WEBSITE}
                title={t('cookiePage.websiteTitle')}
              />
            ),
          }}
        />
      </ParagraphText>
      <HeadingText variant="h4">
        {t('cookiePage.definition.heading')}
      </HeadingText>
      <ParagraphText variant="body1">
        {t('cookiePage.definition.paragraph')}
      </ParagraphText>
      <HeadingText variant="h4">
        {t('cookiePage.whyNeeded.heading')}
      </HeadingText>
      <ParagraphText variant="body1">
        {t('cookiePage.whyNeeded.paragraph')}
      </ParagraphText>
      <HeadingText variant="h4">{t('cookiePage.types.heading')}</HeadingText>
      <ParagraphText variant="body1">
        <Trans i18nKey="cookiePage.types.point1" />
        <br />
        <Trans i18nKey="cookiePage.types.point2" />
        <br />
        <Trans i18nKey="cookiePage.types.point3" />
        <br />
        <Trans i18nKey="cookiePage.types.point4" />
        <br />
        <Trans i18nKey="cookiePage.types.point5" />
      </ParagraphText>
      <HeadingText variant="h4">{t('cookiePage.reject.heading')}</HeadingText>
      <ParagraphText variant="body1">
        <Trans i18nKey="cookiePage.reject.paragraph" />
      </ParagraphText>
      <HeadingText variant="h4">{t('cookiePage.change.heading')}</HeadingText>
      <ParagraphText variant="body1">
        {t('cookiePage.change.paragraph')}
      </ParagraphText>
    </PageContainer>
  );
};

export default CookiePage;
