/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Grid, Typography } from '@mui/material';
import {
  CardContentHolder,
  StyledCard,
  StyledGrid,
  TestResultsHead,
} from './testResultCard.styled';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

const TestResultCard = ({ testResult }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <StyledGrid item xs={12}>
        <TestResultsHead variant="h3">
          {t('fileUploaderPage.fileTest.resultHeading')}
        </TestResultsHead>
      </StyledGrid>
      <Grid item xs={12}>
        <StyledCard>
          <CardContentHolder>
            {testResult.map((result, index) => {
              return (
                <Typography
                  key={index}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  <Trans i18nKey="fileUploaderPage.fileTest.resultData" />
                  {result.content}
                </Typography>
              );
            })}
          </CardContentHolder>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

TestResultCard.propTypes = {
  testResult: PropTypes.array,
};

TestResultCard.defaultProps = {
  testResult: [],
};

export default TestResultCard;
