/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { React, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from '@mui/material';
import {
  FieldGrid,
  HeadingTypography,
  InputField,
  RoleGridContainer,
} from './addUser.styled';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import {
  useUpdateUserMutation,
  useGetUserQuery,
} from '../../redux/services/speciphicAsk';
import { LoadingButton } from '@mui/lab';
import { pushToast } from '../../redux/reducers/toasts.slice';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const EditUserComponent = ({ handleClose, userDetails }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [user, setUser] = useState(null);
  const [role, setRole] = useState('user');
  const [emailId, setEmailId] = useState('');
  const dispatch = useDispatch();

  const {
    data: userData,
    isSuccess: isGetUserSuccess,
    isLoading: isGetUserLoading,
    isError: isGetUserError,
  } = useGetUserQuery({ userId: userDetails });

  const [
    updateUser,
    {
      data: newUser,
      isSuccess: isAddNewUserSuccess,
      isLoading: isAddNewUserLoading,
      isError: isAddNewUserError,
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    setRole(userData?.role);
    setEmailId(userData?.emailId);
  }, [isGetUserSuccess]);

  useEffect(() => {
    if (isAddNewUserSuccess) {
      dispatch(
        pushToast({
          message: t('editUsersPage.pushToast.success'),
          severity: 'success',
        }),
      );
      handleClose();
    }
  }, [isAddNewUserSuccess]);

  useEffect(() => {
    if (isAddNewUserError) {
      dispatch(
        pushToast({
          message: t('editUsersPage.pushToast.error'),
          severity: 'error',
        }),
      );
    }
  }, [isAddNewUserError]);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleEmailInputChange = (e) => {
    e.preventDefault();
    setEmailId(e.target.value);
  };

  const handleRemoveFieldDetails = () => {
    setEmailId('');
    handleClose();
  };

  useState(() => {
    setUser(auth.user);
  }, []);

  const isEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const RoleDropdown = () => {
    return (
      <FormControl size="small" required sx={{ m: 1, minWidth: '100%' }}>
        <InputLabel size="small">
          {t('addUsersPage.roleDropdown.heading')}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={role}
          defaultValue={'user'}
          label="Role"
          onChange={handleRoleChange}
        >
          <MenuItem value={'admin'}>
            {t('addUsersPage.roleDropdown.admin')}
          </MenuItem>
          <MenuItem value={'user'}>
            {t('addUsersPage.roleDropdown.user')}
          </MenuItem>
          <MenuItem value={'owner'}>
            {t('addUsersPage.roleDropdown.owner')}
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  const handleAddNewUser = (event) => {
    event.preventDefault();

    if (isEmail(emailId)) {
      updateUser({
        emailId: emailId,
        role: role,
        userId: userDetails,
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleAddNewUser}>
      <RoleGridContainer container spacing={0}>
        <FieldGrid item xs={12}>
          <HeadingTypography variant="h5">
            {t('addUsersPage.editHeadingText')}
          </HeadingTypography>
        </FieldGrid>

        <FieldGrid item xs={12}>
          <InputField
            size="small"
            required
            disabled
            id="outlined-required"
            label="Email ID"
            value={isGetUserLoading ? 'Loading...' : emailId}
            error={!isEmail}
            helperText={
              emailId?.length > 0 &&
              !isEmail(emailId) && (
                <Alert
                  variant="outlined"
                  severity="error"
                  sx={{
                    padding: 0,
                  }}
                >
                  {t('addUsersPage.invalidEmailAlert')}
                </Alert>
              )
            }
            sx={{
              minWidth: '100%',
            }}
            onChange={handleEmailInputChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <RoleDropdown />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loadingPosition="end"
            onClick={handleAddNewUser}
            loading={isAddNewUserLoading}
            disabled={!isEmail(emailId)}
            sx={{ width: '180px', margin: 2 }}
          >
            {t('editUsersPage.buttonTexts.editUserButton')}
          </LoadingButton>
        </FieldGrid>
        <FieldGrid item xs={12}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleRemoveFieldDetails}
            sx={{ width: '180px', marginBottom: 2 }}
          >
            {t('addUsersPage.buttonTexts.cancelButton')}
          </Button>
        </FieldGrid>
      </RoleGridContainer>
    </Box>
  );
};

EditUserComponent.propTypes = {
  handleClose: PropTypes.func,
  userDetails: PropTypes.string,
};

export default EditUserComponent;
