import PropTypes from 'prop-types';
import DataGridTable from '../../components/datagrid/datagrid.component';
import readableDate from './readableDate';
import QnA from './qna.component';
import FileCollection from './fileCollection.component';

const SearchHistoryDataGrid = ({ chatHistory }) => {
  const columns = [
    {
      field: 'Date',
      valueGetter: (params) => readableDate(params.row.createdAt),
      width: 200,
    },
    {
      field: 'Query',
      renderCell: (params) => (
        <QnA query={params.row.query} results={params.row.results} />
      ),
      width: 600,
      sortable: false,
    },
    {
      field: 'File Collection',
      renderCell: (params) => (
        <FileCollection fileCollectionId={params.row.collection_id} />
      ),
      width: 200,
      sortable: false,
    },
  ];

  return (
    <DataGridTable
      column={columns}
      row={chatHistory}
      rowId="id"
      rowHeightAuto={true}
    />
  );
};

export default SearchHistoryDataGrid;

SearchHistoryDataGrid.propTypes = {
  chatHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
};
