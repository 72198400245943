/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Typography, Button, IconButton, Link, Box } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Thumbsup } from '../../assets/feedback-thumbs-up.svg';
import { ReactComponent as ThumbsDown } from '../../assets/feedback-thumbs-down.svg';
import { ReactComponent as ShareIcon } from '../../assets/answer-share-icon.svg';
import {
  CardContentBox,
  ContentBox,
  StyleShareTypography,
  StyledAnswerTypography,
  StyledCard,
  StyledIconButton,
  StyledStack,
  FeedBackWrapper,
  AnswerComponentBottomWrapper,
  ShareWrapper,
  AnsWrapper,
} from './answer.styled';

const Answer = ({ title, description, meta, feedback, result, query }) => {
  const { t } = useTranslation();
  const [hoverForThumbsup, setHoverForThumbsup] = useState(false);
  const [hoverForThumbsDown, setHoverForThumbsDown] = useState(false);
  const [shareIconColor, setShareIconColor] = useState('secondary');

  const subjectOfMail = t('answer.emailSubject', {
    query: query,
  });

  const bodyOfMail = t('answer.emailBody', {
    title: title,
    description: description,
    meta: meta,
    downloadUriPart: meta?.url,
  });

  const handleFeedbackSend = (bool) => {
    bool
      ? setHoverForThumbsup(true) & setHoverForThumbsDown(false)
      : setHoverForThumbsup(false) & setHoverForThumbsDown(true);
    feedback(result, bool);
  };

  return (
    <>
      <AnsWrapper>
        <ContentBox>
          <StyledAnswerTypography variant="h6">{title}</StyledAnswerTypography>
        </ContentBox>
        <ContentBox>
          <Typography variant="body1">{description}</Typography>

          <AnswerComponentBottomWrapper>
            <FeedBackWrapper>
              <Box>
                <Typography sx={{ fontSize: '18px', fontWeight: 300 }}>
                  {t('answer.feedbackText')}
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={() => handleFeedbackSend(true)}
                sx={{ marginRight: '10px', marginLeft: '10px' }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleFeedbackSend(false)}
              >
                No
              </Button>
            </FeedBackWrapper>
            <ShareWrapper>
              <Link
                href={`mailto:?subject=${subjectOfMail}&body=${bodyOfMail}`}
                target="_blank"
                rel="noreferrer"
                underline="none"
              >
                <StyledIconButton
                  color={shareIconColor}
                  size="small"
                  sx={{ borderRadius: 0 }}
                  onClick={() => setShareIconColor('primary')}
                >
                  <ShareIcon
                    alt={t(
                      'questionAnswerPage.answerFeedback.thumbsDownImgAlt',
                    )}
                  />
                  <StyleShareTypography variant="subtitle1">
                    {t('answer.shareButton')}
                  </StyleShareTypography>
                </StyledIconButton>
              </Link>
            </ShareWrapper>
          </AnswerComponentBottomWrapper>
        </ContentBox>
      </AnsWrapper>
    </>
  );
};

Answer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.array,
  feedback: PropTypes.func,
  query: PropTypes.string,
  result: PropTypes.array,
};

export default Answer;
