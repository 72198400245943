/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { LoadingButton } from '@mui/lab';
import { Alert, Box, ButtonGroup, Grid, styled, Button } from '@mui/material';

export const FilesAddedAlert = styled(Alert)({
  width: '300px',
});

export const AppTitleBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));

export const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  justifyContent: 'left',
  width: 'auto',
}));

export const MobileButton = styled(LoadingButton)(({ theme }) => ({
  display: 'none',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const AddFilesButton = styled(LoadingButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const AddButton = styled(LoadingButton)(({ theme }) => ({
  float: 'right',
  display: 'flex',
  width: 'auto',
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const StyledButtonLeft = styled(LoadingButton)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const StyledButtonRight = styled(LoadingButton)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.up('xs')]: {
    width: '70%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const FileCollectionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
