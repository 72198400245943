/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Alert, LinearProgress, Typography } from '@mui/material';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import AppShadowBox from '../../components/app.shadowbox/app.shadowbox.component';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/pageContainer';
import { useGetChatHistoryQuery } from '../../redux/services/speciphicAsk';
import SearchHistoryDataGrid from './searchHistoryDataGrid.component';

const SearchHistoryPage = () => {
  const auth = useAuth();
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  useState(() => {
    setUser(auth.user);
  }, []);

  const {
    data: chatHistory = [],
    isError: isGetChatHistoryError,
    isLoading: isGetChatHistoryLoading,
    isSuccess: isGetChatHistorySuccess,
  } = useGetChatHistoryQuery();

  const handleSentenceCase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <PageContainer>
      <AppShadowBox>
        {isGetChatHistoryLoading && (
          <>
            <LinearProgress />
            <Typography variant="h5" sx={{ textAlign: 'center', marginTop: 2 }}>
              {t('Loading Search History')}
            </Typography>
          </>
        )}
        {isGetChatHistorySuccess && (
          <SearchHistoryDataGrid chatHistory={chatHistory} />
        )}
      </AppShadowBox>
    </PageContainer>
  );
};

export default SearchHistoryPage;
