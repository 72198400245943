/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { ImageWrapper, ImageContainer } from './app.title.styled';

const AppTitle = ({ subtitle, image }) => {
  return (
    <>
      {image.length !== 0 && (
        <ImageWrapper>
          <ImageContainer className="img">{image}</ImageContainer>
          <Typography sx={{ marginTop: 2, paddingX: 8, fontSize: 18 }}>
            {subtitle}
          </Typography>
        </ImageWrapper>
      )}
    </>
  );
};

AppTitle.propTypes = {
  subtitle: PropTypes.string,
  image: PropTypes.object,
};

AppTitle.defaultProps = {
  image: [],
};

export default AppTitle;
