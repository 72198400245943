import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import PropTypes from 'prop-types';
import { Box, IconButton, LinearProgress } from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { StyledButtonBox, StyledWholePdfBox } from './pdfPreview.styled';

const PdfPreview = ({ pageNumber, pdfUrl, scale, showWholePdf }) => {
  const [page, setPage] = useState(pageNumber);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: pdfUrl,
    page,
    canvasRef,
    scale,
  });

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {!pdfDocument && <LinearProgress />}
      {showWholePdf ? (
        <StyledWholePdfBox>
          <canvas ref={canvasRef} />
        </StyledWholePdfBox>
      ) : (
        <Box style={{ textAlign: 'center' }}>
          <canvas ref={canvasRef} />
        </Box>
      )}

      {showWholePdf && Boolean(pdfDocument && pdfDocument.numPages) && (
        <StyledButtonBox>
          <IconButton
            disabled={page === 1}
            onClick={handlePrevPage}
            color="primary"
          >
            <NavigateBefore />
          </IconButton>
          <IconButton
            disabled={page === pdfDocument.numPages}
            onClick={handleNextPage}
            color="primary"
          >
            <NavigateNext />
          </IconButton>
        </StyledButtonBox>
      )}
    </div>
  );
};

PdfPreview.propTypes = {
  pageNumber: PropTypes.number,
  pdfUrl: PropTypes.string,
  scale: PropTypes.number,
  showWholePdf: PropTypes.bool,
};

PdfPreview.defaultProps = {
  pageNumber: 1,
  scale: 1,
};

export default PdfPreview;
