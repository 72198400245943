/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { getUserFromSession } from '../../user';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  SPECIPHIC_ASK_API_URL,
  API_V1_PATH,
} from '../../../constants/apiPaths';
import {
  resultsEPFactory,
  addFeedbackEPFactory,
  modelsEPFactory,
  addModelsEPFactory,
  fileCollectionsEPFactory,
  addCollectionsEPFactory,
  storeCollectionsEPFactory,
  testCollectionsEPFactory,
  deleteCollectionsEPFactory,
  deleteCollectionStoreEPFactory,
  deleteIndividualFileEPFactory,
  getIndividualFileCollectionEPFactory,
  updateFileCollectionEPFactory,
  addChatHistoryEPFactory,
  getChatHistoryEPFactory,
  getLanguagesEPFactory,
  getSearchEnginesEPFactory,
  getFaqEPFactory,
  getUsersEPFactory,
  addNewUserEPFactory,
  deleteUserEPFactory,
  getUserEPFactory,
  getUserbyEmailEPFactory,
  updateUserEPFactory,
  addEmptyCollectionEPFactory,
  addDataSourceEPFactory,
  addFilesToCollectionEPFactory,
  getDataSourceEPFactory,
} from './endpoints';

export const speciphicAskApi = createApi({
  reducerPath: 'speciphicAskApi',
  tagTypes: [
    'fileCollections',
    'individualFileCollections',
    'chatHistory',
    'users',
    'data-source',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${SPECIPHIC_ASK_API_URL}/${API_V1_PATH}`,
    prepareHeaders: (headers) => {
      const user = getUserFromSession();
      const token = user?.access_token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getResults: resultsEPFactory(builder),
    addFeedback: addFeedbackEPFactory(builder),
    getModels: modelsEPFactory(builder),
    addModels: addModelsEPFactory(builder),
    getFileCollections: fileCollectionsEPFactory(builder),
    addFileCollections: addCollectionsEPFactory(builder),
    testFileCollections: testCollectionsEPFactory(builder),
    storeFileCollections: storeCollectionsEPFactory(builder),
    updateFileCollection: updateFileCollectionEPFactory(builder),
    deleteCollections: deleteCollectionsEPFactory(builder),
    deleteStore: deleteCollectionStoreEPFactory(builder),
    deleteIndividualFile: deleteIndividualFileEPFactory(builder),
    getIndividualFileCollection: getIndividualFileCollectionEPFactory(builder),
    addChatHistory: addChatHistoryEPFactory(builder),
    getChatHistory: getChatHistoryEPFactory(builder),
    getLanguages: getLanguagesEPFactory(builder),
    getSearchEngines: getSearchEnginesEPFactory(builder),
    getFaq: getFaqEPFactory(builder),
    getUsers: getUsersEPFactory(builder),
    addNewUser: addNewUserEPFactory(builder),
    deleteUser: deleteUserEPFactory(builder),
    getUser: getUserEPFactory(builder),
    getUserbyEmail: getUserbyEmailEPFactory(builder),
    updateUser: updateUserEPFactory(builder),
    addEmptyCollection: addEmptyCollectionEPFactory(builder),
    addDataSource: addDataSourceEPFactory(builder),
    addFilesToCollection: addFilesToCollectionEPFactory(builder),
    getDataSource: getDataSourceEPFactory(builder),
  }),
});

export const {
  useGetResultsMutation,
  useGetModelsQuery,
  useAddFeedbackMutation,
  useAddModelsMutation,
  useLazyGetModelsQuery,
  useGetFileCollectionsQuery,
  useGetIndividualFileCollectionQuery,
  useUpdateFileCollectionMutation,
  useAddFileCollectionsMutation,
  useLazyGetFileCollectionsQuery,
  useTestFileCollectionsMutation,
  useStoreFileCollectionsMutation,
  useDeleteCollectionsMutation,
  useDeleteStoreMutation,
  useDeleteIndividualFileMutation,
  useAddChatHistoryMutation,
  useGetChatHistoryQuery,
  useGetLanguagesQuery,
  useLazyGetSearchEnginesQuery,
  useGetSearchEnginesQuery,
  useGetFaqMutation,
  useGetUsersQuery,
  useAddNewUserMutation,
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useLazyGetUserbyEmailQuery,
  useAddEmptyCollectionMutation,
  useAddDataSourceMutation,
  useAddFilesToCollectionMutation,
  useGetDataSourceQuery,
} = speciphicAskApi;
