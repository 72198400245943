import { Box, styled } from '@mui/material';

export const StyledWholePdfBox = styled(Box)(({ theme }) => ({
  overflowX: 'auto',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  padding: theme.spacing(0),
  boxSizing: 'border-box',
}));

export const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
}));
