/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  Box,
  styled,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const BoxWrapper = styled(Box)(() => ({
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

export const ButtonLink = styled(Button)(({ theme }) => ({
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    float: 'right',
  },
  [theme.breakpoints.up('md')]: {
    width: 'auto',
    float: 'right',
  },
  float: 'right',
}));

export const InputField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  width: 250,
  margin: theme.spacing(1),
}));

export const FieldGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const RoleGridContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  marginTop: theme.spacing(10),
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const StyledButtonLeft = styled(LoadingButton)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const StyledButtonRight = styled(LoadingButton)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  color: theme.palette.primary.main,
  [theme.breakpoints.up('xs')]: {
    width: '70%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));
