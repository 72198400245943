/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ accountType, name, accountName, accessKey }) => ({
      url: `data-source`,
      method: 'POST',
      body: {
        account_type: accountType,
        connection_name: name,
        account_name: accountName,
        account_key: accessKey,
      },
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['data-source'],
  });
