/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ name, indexName, files, searchEngine, jsonFileData }) => {
      const formData = new FormData();
      const data = {};
      data.name = name;
      data.storeIndexName = indexName;
      data.searchEngine = searchEngine;

      if (searchEngine === 'EXTRACTIVE') data.appConfig = 'speciphic_nlp';
      if (searchEngine === 'GENERATIVE')
        data.appConfig = 'openai_configuration';
      if (searchEngine === 'AZURE_OPENAI')
        data.appConfig = 'azure_openai_configuration';
      if (searchEngine === 'MICROSOFT')
        data.appConfig = 'azure_openai_configuration';

      data.filesMeta = JSON.parse(jsonFileData);
      formData?.append('data', JSON.stringify(data));
      files?.forEach((file) => {
        formData.append('files', file);
      });
      return {
        url: `file-collections`,
        method: 'POST',
        body: formData,
      };
    },
    invalidatesTags: ['fileCollections'],
  });
