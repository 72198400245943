/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import {
  StyledAvatar,
  AvatarLetterWrap,
  NavListWrap,
  AccountText,
} from './app.navbar.styled';
import NirmalAvatar from '../../assets/nirmal-avatar.svg';
import { USER, ADMIN, SUJIT_EMAIL } from '../../constants/roles';

export const AppNavbarProfile = ({
  userName,
  userEmail,
  profileClick,
  settingsClick,
  logoutClick,
  usersClick,
  searchHistoryClick,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const userRole = useSelector((state) => state?.userRole) || USER;

  return (
    <NavListWrap>
      <ListItem>
        <ListItemText
          primary="ACCOUNT"
          primaryTypographyProps={{ variant: 'h5' }}
        ></ListItemText>
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          {auth.user.profile?.preferred_username === SUJIT_EMAIL ? (
            <StyledAvatar alt="Sujit" src={NirmalAvatar} />
          ) : (
            <StyledAvatar>
              <AvatarLetterWrap>
                {userName?.charAt(0) || auth.user.profile.oid?.charAt(0)}
              </AvatarLetterWrap>
            </StyledAvatar>
          )}
        </ListItemAvatar>
        <AccountText
          primary={
            userName + ' (' + userRole + ')' ||
            t('app.navbar.accountText.primary')
          }
          // secondary={userEmail || t('app.navbar.accountText.secondary')}
        ></AccountText>
      </ListItem>
      <Divider sx={{ marginTop: 1 }} />
      {/* <ListItemButton onClick={profileClick}>
        <ListItemText primary={t('app.navbar.profile')}></ListItemText>
      </ListItemButton> */}
      <ListItemButton onClick={searchHistoryClick}>
        <ListItemText primary={t('app.navbar.searchHistory')}></ListItemText>
      </ListItemButton>
      {userRole === ADMIN && (
        <>
          <ListItemButton onClick={settingsClick}>
            <ListItemText primary={t('app.navbar.setting')}></ListItemText>
          </ListItemButton>
          <ListItemButton onClick={usersClick}>
            <ListItemText primary={t('app.navbar.users')}></ListItemText>
          </ListItemButton>
        </>
      )}
      <Divider />
      <ListItemButton onClick={logoutClick}>
        <ListItemText primary={t('app.navbar.logout')}></ListItemText>
      </ListItemButton>
    </NavListWrap>
  );
};

AppNavbarProfile.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  profileClick: PropTypes.func,
  searchHistoryClick: PropTypes.func,
  settingsClick: PropTypes.func,
  logoutClick: PropTypes.func,
  usersClick: PropTypes.func,
};
