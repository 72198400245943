/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, Icon, styled, Typography } from '@mui/material';
import dropzoneBg from '../../assets/dropzone-bg-poster.svg';

export const UploadSection = styled(Box)({
  display: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

export const DropZone = styled(Box)(({ isDragActive, theme, disabled }) => ({
  outlineStyle: 'dashed',
  outlineWidth: isDragActive ? 3 : 2,
  outlineColor: disabled
    ? theme.palette.secondary.main
    : theme.palette.primary.main,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0, 2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  backgroundImage: `url(${dropzoneBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export const UploadIcon = styled(Icon)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.secondary.main : theme.palette.primary.main,
  width: 67,
  height: 52,
}));

export const UploadButtonText = styled(Typography)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.secondary.main : theme.palette.primary.main,
  fontWeight: 500,
}));
