/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SpeciphicAskLogo } from '../../assets/ask-logo.svg';
import * as PATHS from '../../constants/path';
import {
  StyledFooterPaper,
  StyledNavigationSection,
  StyledLeftSection,
  FooterLink,
  StyledTypography,
  LogoWrap,
  LogoBox,
} from './app.footer.style';

const AppFooter = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <StyledFooterPaper ref={ref}>
      <StyledNavigationSection>
        <StyledLeftSection>
          <FooterLink target="_blank" href={PATHS.COOKIES}>
            {t('appFooter.cookieLink')}
          </FooterLink>
          <FooterLink target="_blank" href={PATHS.PRIVACY_POLICY}>
            {t('appFooter.privacyPolicyLink')}
          </FooterLink>
          <FooterLink target="_blank" href={PATHS.TERMS_OF_USE}>
            {t('appFooter.termsOfUseLink')}
          </FooterLink>
        </StyledLeftSection>
        <LogoBox>
          <StyledTypography variant="caption">
            {t('appFooter.poweredByText')}
          </StyledTypography>
          <LogoWrap>
            <SpeciphicAskLogo style={{ width: '100px', height: 'auto' }} />
          </LogoWrap>
        </LogoBox>
      </StyledNavigationSection>
    </StyledFooterPaper>
  );
});

AppFooter.displayName = 'AppFooter';

export default AppFooter;
