/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ collection_id, answer, isRight, meta, query }) => {
      const formData = new FormData();
      formData.append('collection_id', collection_id);
      formData.append('answer', answer);
      formData.append('isRight', isRight);
      formData.append('query', query);
      formData.append('meta', meta);

      return {
        url: `feedback`,
        method: 'POST',
        body: formData,
      };
    },
  });
