import { UsersPageWrapper } from './users.styled';
import AppShadowBox from '../../components/app.shadowbox';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/pageContainer';
import { useGetUsersQuery } from '../../redux/services/speciphicAsk';
import UsersDataGrid from './usersDataGrid.component';
import { LinearProgress, Typography } from '@mui/material';

const UsersPage = () => {
  const { t } = useTranslation();

  const {
    data: users,
    isSuccess: isGetUsersSuccess,
    isLoading: isGetUsersLoading,
    isError: isGetUsersError,
  } = useGetUsersQuery();

  return (
    <PageContainer>
      <UsersPageWrapper>
        <AppShadowBox>
          {isGetUsersLoading && (
            <>
              <LinearProgress />
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', marginTop: 2 }}
              >
                {t('usersPage.loadingMessage.loadingFileCollections')}
              </Typography>
            </>
          )}
          {isGetUsersSuccess && <UsersDataGrid users={users} />}
        </AppShadowBox>
      </UsersPageWrapper>
    </PageContainer>
  );
};

export default UsersPage;
