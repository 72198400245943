/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, Button, Typography, styled } from '@mui/material';

export const PageWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(6),
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  minHeight: '100%',
  maxHeight: `calc(100vh - ${theme.spacing(12)}px)`,
}));

export const TextWarpper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '80%',
  },
}));

export const PosterWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '40%',
  },
  margin: '0 auto',
}));

export const Heading = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(8),
  marginTop: theme.spacing(2),
  color: theme.palette.primary.main,
  textAlign: 'center',
}));

export const SubHeading1 = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.secondary.dark,
  textAlign: 'center',
}));

export const SubHeading2 = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
  textAlign: 'center',
}));

export const HomeButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(10),
}));
