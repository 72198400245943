/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Typography, Box, styled } from '@mui/material';

export const ErrorTypography = styled(Typography)(() => ({
  margin: 10,
}));

export const StyledPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  overflowY: 'auto',
  margin: theme.spacing(2),
  padding: theme.spacing(1),
  minWidth: 'fit-content',
}));
