import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Popover,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../fileUploader.page.styled';
import DropdownOne from '../../../../../components/dropdownone';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../../../redux/reducers/toasts.slice';
import {
  useAddDataSourceMutation,
  useGetDataSourceQuery,
} from '../../../../../redux/services/speciphicAsk';

const storageConfigs = [
  { label: 'Azure Blob', value: 'AZURE_BLOB' },
  { label: 'AWS S3', value: 'AWS_S3' },
  { label: 'FTP', value: 'FTP' },
  { label: 'Scrap a website', value: 'SCRAP' },
];

const StorageConfiguration = ({ onStorageChange }) => {
  const [name, setName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accessKey, setAccessKey] = useState('');
  const [configType, setConfigType] = useState('AZURE_BLOB');
  const [filledForm, setFilledForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const createNewMenuItemRef = useRef(null);
  const dispatch = useDispatch();
  const handleAccountNameChange = (event) => {
    setAccountName(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleAccessKeyChange = (event) => {
    setAccessKey(event.target.value);
  };

  const handleStorageChange = (event) => {
    if (event.target.id === 'create-new-menu-item') {
      handleOpenPopover(event.target.value);
    } else {
      onStorageChange(event.target.value);
    }
  };

  const [
    addNewConnection,
    {
      data: newConnection,
      isSuccess: isAddConnectionSuccess,
      isLoading: isAddConnectionLoading,
      isError: isGetConnectionError,
    },
  ] = useAddDataSourceMutation();

  const {
    data: dataSources,
    isSuccess: isGetDataSourceSuccess,
    isLoading: isGetDataSourceLoading,
    isError: isGetDataSourceError,
  } = useGetDataSourceQuery();

  useEffect(() => {
    if (isAddConnectionSuccess) {
      handleClosePopover();
      dispatch(
        pushToast({
          message: t(
            'fileUploaderPage.storageConfiguration.newSourcePopover.addNewConnectionSuccess',
          ),
          severity: 'success',
        }),
      );
    }
  }, [isAddConnectionSuccess]);

  const handleAddNewStorage = () => {
    addNewConnection({
      accountType: configType,
      name: name,
      accountName: accountName,
      accessKey: accessKey,
    });
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event);
    handleClearFields();
  };

  const handleClearFields = () => {
    setName('');
    setAccountName('');
    setAccessKey('');
  };

  const handleClosePopover = () => {
    setConfigType('AZURE_BLOB');
    setAnchorEl(null);
  };

  const handleStorageConfigChange = (value) => {
    setConfigType(value);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'storage-configuration-popover' : undefined;

  useEffect(() => {
    if (name.length > 0 && accountName.length > 0 && accessKey.length > 0) {
      setFilledForm(true);
    }
  }, [filledForm, name, accountName, accessKey]);

  return (
    <>
      <FormControl sx={{ width: '100%' }} size="small">
        <InputLabel size="small">
          {t('fileUploaderPage.storageConfiguration.dataSourceDropdown')}
        </InputLabel>
        <Select
          label={t('fileUploaderPage.storageConfiguration.dataSourceDropdown')}
          size="small"
          defaultValue=""
          onChange={handleStorageChange}
        >
          {isGetDataSourceSuccess &&
            dataSources.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.connectionName}
              </MenuItem>
            ))}
          {isGetDataSourceLoading && <LinearProgress />}

          <MenuItem
            id="create-new-menu-item"
            value=""
            ref={createNewMenuItemRef}
            onClick={handleOpenPopover}
          >
            {t('fileUploaderPage.storageConfiguration.createNewOption')}
          </MenuItem>
        </Select>
      </FormControl>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            width: '300px',
            height: '420px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="h6" style={{ marginBottom: 12 }}>
              {t(
                'fileUploaderPage.storageConfiguration.newSourcePopover.title',
              )}
            </Typography>
            <DropdownOne
              onChange={handleStorageConfigChange}
              defaultValue={'AZURE_BLOB'}
              label="Storage Container"
              value={configType}
              items={storageConfigs}
              sx={{ marginTop: 2 }}
            />
          </Box>
          {configType === 'AZURE_BLOB' && (
            <>
              <InputField
                sx={{ marginTop: 2 }}
                size="small"
                required
                fullWidth
                id="outlined-required"
                label={t(
                  'fileUploaderPage.storageConfiguration.newSourcePopover.name',
                )}
                value={name}
                onChange={handleNameChange}
              />

              <InputField
                sx={{ marginTop: 2 }}
                size="small"
                required
                fullWidth
                id="outlined-required"
                label={t(
                  'fileUploaderPage.storageConfiguration.newSourcePopover.accountName',
                )}
                value={accountName}
                onChange={handleAccountNameChange}
              />
              <InputField
                sx={{ marginTop: 2 }}
                size="small"
                fullWidth
                required
                id="outlined-required"
                label={t(
                  'fileUploaderPage.storageConfiguration.newSourcePopover.accessKey',
                )}
                value={accessKey}
                onChange={handleAccessKeyChange}
              />
            </>
          )}
          <Box sx={{ display: 'block' }}>
            <LoadingButton
              onClick={handleAddNewStorage}
              variant="contained"
              size="medium"
              color="primary"
              disabled={configType !== 'AZURE_BLOB' || !filledForm}
              fullWidth
              sx={{ marginTop: 2 }}
              loading={isAddConnectionLoading}
              loadingPosition="end"
            >
              {t(
                'fileUploaderPage.storageConfiguration.createConnectionButton',
              )}
            </LoadingButton>
            <Button
              size="medium"
              onClick={() => {
                {
                  configType === 0 && handleClearFields();
                }
                handleClosePopover();
              }}
              variant="outlined"
              fullWidth
              sx={{ marginTop: 2, marginBottom: 2 }}
            >
              {t('fileUploaderPage.storageConfiguration.cancelButton')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

StorageConfiguration.propTypes = {
  onStorageChange: PropTypes.func,
};

export default StorageConfiguration;
