/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Avatar, Box, styled, TableContainer } from '@mui/material';
// import dummyProfileIcon from '../../assets/profile-icon.png';

export const StyledPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const AvatarLetter = styled(Avatar)(({ theme }) => ({
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
  border: `4px solid ${theme.palette.primary.main}`,
  fontSize: theme.typography.pxToRem(50),
  width: 130,
  height: 130,
}));

// export const StyledProfileImage = styled(Box)(({ theme }) => ({
//   width: theme.spacing(19),
//   height: theme.spacing(19),
//   borderRadius: '50%',
//   backgroundImage: `url(${dummyProfileIcon})`,
//   backgroundSize: 'contain',
//   backgroundRepeat: 'no-repeat',
//   marginTop: theme.spacing(2),
// }));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'inherit',
}));
