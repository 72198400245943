/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import PropTypes from 'prop-types';

const LinkText = (props) => {
  return (
    <a
      href={props.to || '#'}
      target="_blank"
      rel="noreferrer"
      title={props.title || ''}
    >
      {props.children}
    </a>
  );
};

LinkText.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.string,
};

export default LinkText;
