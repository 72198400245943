import { Alert, Box, Button, Typography, styled } from '@mui/material';
import fontWeights from '../../themes/fontWeights';
import { color } from '@uiw/react-codemirror';
export const HistoryButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  borderTopLeftRadius: 0,
  borderEndStartRadius: 0,
  zIndex: 1,
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

export const ShadowBox = styled(Box)(({ theme }) => ({
  //boxShadow: '0px 0px 68px rgba(0, 0, 0, 0.15)',
  borderRadius: theme.spacing(0),
  padding: theme.spacing(3, 0),
  width: '100%',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
}));

export const ShadowBoxNormal = styled(Box)(({ theme }) => ({
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(0),
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
}));

export const AnswerComponentWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(0, 4),
}));

export const AppTitleBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));

export const LoadingTypography = styled(Typography)({
  textAlign: 'center',
  margin: 3,
});

export const ResultBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

export const FoundDataAlert = styled(Alert)({
  background: '#33C000',
  color: '#ffffff !important',
  mb: 2,
  '.MuiAlert-icon': { alignItems: 'center', color: '#fff' },
  '.MuiAlert-message': { wordBreak: 'break-all' },
});

export const ResultTitle = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(4, 0, 3),
  fontWeight: '300',
  fontSize: '24px',
  color: theme.palette.text.caption,
}));
