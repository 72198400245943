/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createTheme } from '@mui/material/styles';
import colors from '../colors';

let { palette } = createTheme();

palette = {
  ...palette,
  primary: {
    main: '#EF4F26',
  },
  secondary: {
    main: '#BBBBBB',
    light: '#DDDDDD',
    dark: '1F1F1F',
  },
  text: {
    primary: '#1F1F1F',
    secondary: '#666666',
    light: '#999999',
    caption: '#333333',
    controls: '#333333',
    disabled: '#DDDDDD',
  },
  background: {
    pageBackground: '#F8F8F8',
    white: '#FFFFFF',
  },
  border: {
    main: '#091E42',
    secondary: '#DFEAF3',
    light: '#E1E1E1',
    grey: '#ECECEC',
  },
  poster: {
    main: '#FF8B6D',
  },
  spinner: {
    red: colors.red.R300,
    green: colors.green.G300,
    yellow: colors.yellow.Y300,
    background: '#999999',
  },
};

export default palette;
