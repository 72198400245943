/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, styled } from '@mui/material';

export const ShadowBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[25],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
}));
