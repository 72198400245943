/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import sidebarReducer from './reducers/sidebar.slice';
import toastsReducer from './reducers/toasts.slice';
import { rtkQueryErrorMiddleware } from './rtkQueryError.middleware';
import { speciphicAskApi } from './services/speciphicAsk';
import fileCollectionReducer from './reducers/fileCollection.slice';
import userRoleReducer from './reducers/userRole.slice';

const store = configureStore({
  reducer: {
    [speciphicAskApi.reducerPath]: speciphicAskApi.reducer,
    sidebar: sidebarReducer,
    toasts: toastsReducer,
    fileCollection: fileCollectionReducer,
    userRole: userRoleReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rtkQueryErrorMiddleware,
      speciphicAskApi.middleware,
    ),
});

export default store;

setupListeners(store.dispatch);
