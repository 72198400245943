/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { ProtectedRoute } from '../protectedRoute/ProtectedRoute';
import * as PATHS from '../../constants/path';
import * as ROLES from '../../constants/roles';
import ProfilePage from '../../pages/profilePage';
import NotFoundPage from '../../pages/notFoundPage';
import HomePage from '../../pages/homePage';
import FileUploaderPage from '../../pages/settings/fileUploaderPage';
import CookiePage from '../../pages/cookiePage';
import PrivacyPolicyPage from '../../pages/privacyPolicyPage';
import TermsOfUsePage from '../../pages/termsOfUsePage';
import LegalPage from '../../pages/legalPage';
import FileCollectionsPage from '../../pages/settings/fileCollectionsPage';
import IndividualFileCollectionPage from '../../pages/settings/individualFileCollectionPage';
import SearchPage from '../../pages/searchPage';
import SearchHistoryPage from '../../pages/searchHistoryPage';
import UsersPage from '../../pages/users/users.page';
import LogoutPage from '../../pages/logoutPage';

const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      {/* <Route path={PATHS.HOMEPAGE} element={<HomePage />} /> */}
      <Route path={PATHS.SERVICES} element={<Navigate to={PATHS.HOMEPAGE} />} />
      <Route
        path={PATHS.HOMEPAGE}
        element={
          <ProtectedRoute path={`${location.pathname}${location.search}`}>
            <SearchPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PATHS.SEARCH_HISTORY}
        element={
          <ProtectedRoute path={location.pathname}>
            <SearchHistoryPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PATHS.USERS}
        element={
          <ProtectedRoute path={location.pathname} allowedRoles={[ROLES.ADMIN]}>
            <UsersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PATHS.SETTINGS}
        element={
          <ProtectedRoute path={location.pathname} allowedRoles={[ROLES.ADMIN]}>
            <FileCollectionsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PATHS.SETTINGS_FILE_UPLOAD}
        element={
          <ProtectedRoute path={location.pathname}>
            <FileUploaderPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PATHS.SETTINGS_FILE_COLLECTIONS}
        element={
          <ProtectedRoute path={location.pathname}>
            <FileCollectionsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${PATHS.SETTINGS_FILE_COLLECTIONS}/:rowID`}
        element={
          <ProtectedRoute path={location.pathname}>
            <IndividualFileCollectionPage />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path={PATHS.PROFILE}
        element={
          <ProtectedRoute path={location.pathname}>
            <ProfilePage />
          </ProtectedRoute>
        }
      /> */}
      <Route path={PATHS.NOT_FOUND} element={<NotFoundPage />} />
      <Route path={PATHS.COOKIES} element={<CookiePage />} />
      <Route path={PATHS.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route path={PATHS.TERMS_OF_USE} element={<TermsOfUsePage />} />
      <Route path={PATHS.LEGAL} element={<LegalPage />} />
      <Route path={PATHS.LOGOUT} element={<LogoutPage />} />
    </Routes>
  );
};

export default AppRoutes;
