/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { LinearProgress, Typography, Grid, Alert } from '@mui/material';
import PageContainer from '../../../components/pageContainer';
import AppTitle from '../../../components/app.title';
import AppShadowBox from '../../../components/app.shadowbox';
import FileCollectionDataGrid from './components/fileCollectionDataGrid';
import { useTranslation } from 'react-i18next';
import {
  FileCollectionsPageWrapper,
  StyledFileUploadButton,
} from './fileCollectionsPage.styled';
import { useGetFileCollectionsQuery } from '../../../redux/services/speciphicAsk';
import * as PATHS from '../../../constants/path';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const FileCollectionsPage = () => {
  const { t } = useTranslation();

  const {
    data: fileCollections = [],
    isError: isGetFileCollectionsError,
    isLoading: isGetFileCollectionsLoading,
    isSuccess: isGetFileCollectionsSuccess,
  } = useGetFileCollectionsQuery({});

  return (
    <PageContainer>
      <FileCollectionsPageWrapper>
        <AppTitle
          heading={t('fileCollectionsPage.title')}
          subtitle={t('fileCollectionsPage.subtitle')}
        />

        <AppShadowBox>
          {isGetFileCollectionsLoading && (
            <>
              <LinearProgress />
              <Typography variant="h5">
                {t('fileCollectionsPage.loadingMessage.loadingFileCollections')}
              </Typography>
            </>
          )}

          <>
            {isGetFileCollectionsSuccess && (
              <>
                <FileCollectionDataGrid fileCollections={fileCollections} />
              </>
            )}
            {isGetFileCollectionsError && (
              <>
                <Grid
                  container
                  spacing={1}
                  marginBottom={1}
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Grid item xs={12} sm={4} md={'auto'}>
                    <StyledFileUploadButton
                      variant="contained"
                      size="medium"
                      component={Link}
                      to={PATHS.SETTINGS_FILE_UPLOAD}
                      startIcon={<Add />}
                    >
                      {t('fileCollectionsPage.buttonText.fileUpload')}
                    </StyledFileUploadButton>
                  </Grid>
                </Grid>
                <Alert severity="error">
                  {t('fileCollectionsPage.alertNoCollection')}
                </Alert>
              </>
            )}
          </>
        </AppShadowBox>
      </FileCollectionsPageWrapper>
    </PageContainer>
  );
};

export default FileCollectionsPage;
