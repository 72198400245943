/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  OutlinedInput,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import {
  StyledFormControl,
  DisplayText,
  StyledBox,
  DropdownTextWrapper,
} from './dropdown.styled';
import PropTypes from 'prop-types';

const Dropdown = ({
  items,
  onChange,
  value,
  noOptionsMessage,
  placeholder,
  isLoading,
  loadingMessage,
  searchEnginePlaceholder,
  label,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <StyledFormControl
      size="small"
      sx={{
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: 'none',
          },
      }}
    >
      <InputLabel
        // id="select-label"
        shrink={!isLoading}
        sx={{ width: 'calc(100% - 48px)' }}
      >
        {isLoading ? (
          <StyledBox>
            <Typography variant="body1">{loadingMessage}</Typography>
            <CircularProgress size="1.3rem" />
          </StyledBox>
        ) : (
          label && `${placeholder}`
        )}
      </InputLabel>

      <Select
        labelId="select-label"
        // label={placeholder}
        value={value}
        renderValue={(value) => {
          const currentItem = items.find((item) => item.value === value);
          return (
            <DropdownTextWrapper>
              <DisplayText
                variant="body1"
                sx={{ fontSize: '14px', fontWeight: 600, pb: 0 }}
              >
                {currentItem ? currentItem?.label : noOptionsMessage}
              </DisplayText>
              <Typography
                variant="caption text"
                sx={{ fontSize: '14px', fontWeight: 300, lineHeight: '14px' }}
              >
                {searchEnginePlaceholder}
              </Typography>
            </DropdownTextWrapper>
          );
        }}
        disabled={items.length === 0}
        displayEmpty={!isLoading}
        onChange={handleChange}
        sx={{
          width: '100%',
          background: (theme) => theme.palette.dropdown.primary,
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          border: 'none',

          height: '44px',
        }}
        // input={
        //   isLoading ? undefined : <OutlinedInput notched label={placeholder} />
        // }
        MenuProps={{
          PaperProps: { sx: { width: '250px' } },
        }}
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
      >
        {items.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            <Box>
              <Typography variant="body1">{item.label}</Typography>
              <Typography variant="body2" sx={{ whiteSpace: 'break-spaces' }}>
                {item.description}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  value: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  searchEnginePlaceholder: PropTypes.string,
  label: PropTypes.bool,
};

Dropdown.defaultProps = {
  items: [],
  isLoading: false,
};

export default Dropdown;
