/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ modelName, modelDesc, modelfiles, configFiles }) => {
      const formData = new FormData();
      formData.append('name', modelName);
      formData.append('description', modelDesc);
      modelfiles?.forEach((file) => {
        formData.append('modelFile', file);
      });
      configFiles?.forEach((file) => {
        formData.append('modelConfigFile', file);
      });
      return {
        url: `models`,
        method: 'POST',
        body: formData,
      };
    },
  });
