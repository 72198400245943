/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Card, CardContent, Grid, styled, Typography } from '@mui/material';

export const TestResultsHead = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.primary.main,
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: 350,
  },
  overflowY: 'auto',
}));

export const CardContentHolder = styled(CardContent)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(1),
}));
