import {
  Box,
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  Alert,
  AlertTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteForever, Edit } from '@mui/icons-material';
import {
  DialogActionsWrapper,
  StyledClearIcon,
  StyledDataGrid,
  StyledSearchIcon,
  StyledWrapper,
} from './users.styled';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomToolbar from './usersDataGridCustomToolbar.component';
import { useEffect, useState } from 'react';
import { useDeleteUserMutation } from '../../redux/services/speciphicAsk';
import { pushToast } from '../../redux/reducers/toasts.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as PATHS from '../../constants/path';
import { EditUserComponent } from '../../components/addUser.component';

const UsersDataGrid = ({ users }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userIdDelete, setUserIdDelete] = useState('');
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const dispatch = useDispatch();
  const [
    deleteUser,
    {
      isError: isDeleteUserError,
      isLoading: isDeleteUserLoading,
      isSuccess: isDeleteUserSuccess,
    },
  ] = useDeleteUserMutation();

  const handleDeleteUser = (userIdDelete) => {
    setOpenDeleteUser(false);
    deleteUser({
      userId: userIdDelete,
    });
  };

  const openDeleteUserDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteUser(true);
    setUserIdDelete(row.id);
  };

  const closeDelete = () => {
    setOpenDeleteUser(false);
  };

  const handleUpdateClick = (e, row) => {
    setOpenEdit(true);
    setUserDetails(row.id);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    if (isDeleteUserSuccess) {
      dispatch(
        pushToast({
          message: t('usersPage.dataGrid.deleteSuccessToast'),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteUserSuccess]);

  useEffect(() => {
    if (isDeleteUserError) {
      dispatch(
        pushToast({
          message: t('usersPage.dataGrid.deleteErrorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteUserError]);

  const getFirstNameColumn = (props) => {
    return <StyledWrapper>{props.row.firstName}</StyledWrapper>;
  };

  const getLastNameColumn = (props) => {
    return <StyledWrapper>{props.row.lastName}</StyledWrapper>;
  };

  const getEmailIdColumn = (props) => {
    return <StyledWrapper>{props.row.emailId}</StyledWrapper>;
  };

  const getRoleColumn = (props) => {
    return <StyledWrapper>{props.row.role}</StyledWrapper>;
  };

  const actionColumn = (props) => {
    return (
      <>
        <Tooltip title={t('usersPage.dataGrid.actionButton.update')} arrow>
          <LoadingButton
            onClick={(e) => handleUpdateClick(e, props.row)}
            // loading={isDeleteUserLoading}
          >
            <span>
              <Edit />
            </span>
          </LoadingButton>
        </Tooltip>
        <Tooltip title={t('usersPage.dataGrid.actionButton.delete')} arrow>
          <LoadingButton
            onClick={(e) => openDeleteUserDialog(e, props.row)}
            loading={userIdDelete === props.row.id && isDeleteUserLoading}
          >
            <span>
              <DeleteForever />
            </span>
          </LoadingButton>
        </Tooltip>
      </>
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: 'email',
      headerName: 'Email ID',
      width: 300,
      headerAlign: 'left',
      align: 'left',
      renderCell: getEmailIdColumn,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: getRoleColumn,
    },
    {
      minWidth: 10,
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Update/Delete User',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  const DeleteDialogPopup = () => {
    return (
      <Dialog open={openDeleteUser}>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>
              {t('usersPage.dataGrid.deletePopup.heading')}
            </AlertTitle>
            <AlertTitle>
              <strong>{t('usersPage.dataGrid.deletePopup.warning')}</strong>
            </AlertTitle>
          </Alert>
        </DialogContent>
        <DialogActionsWrapper>
          <Button variant="outlined" size="small" onClick={closeDelete}>
            {t('usersPage.dataGrid.deletePopup.cancelButtonText')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDeleteUser(userIdDelete)}
          >
            {t('usersPage.dataGrid.deletePopup.deleteButtonText')}
          </Button>
        </DialogActionsWrapper>
      </Dialog>
    );
  };
  return (
    <Box>
      <StyledDataGrid
        sx={{
          borderColor: 'white',
        }}
        autoHeight
        pageSize={5}
        rows={users}
        disableSelectionOnClick
        disableColumnMenu
        columns={columns}
        getRowId={(row) => row.id}
        components={{
          Toolbar: CustomToolbar,
          QuickFilterIcon: StyledSearchIcon,
          QuickFilterClearIcon: StyledClearIcon,
        }}
      />
      <DeleteDialogPopup />
      <Dialog
        onClose={handleClose}
        open={openEdit}
        style={{ width: '350px', margin: 'auto' }}
      >
        <EditUserComponent
          handleClose={handleClose}
          userDetails={userDetails}
        />
      </Dialog>
    </Box>
  );
};

UsersDataGrid.propTypes = {
  row: PropTypes.object,
  users: PropTypes.array,
};

export default UsersDataGrid;
