/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Divider, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HeadingText } from './termsOfUse.styled';
import PageContainer from '../../components/pageContainer';
import * as PATHS from '../../constants/path';
import LinkText from '../../components/linkText';

const TermsOfUsePage = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <HeadingText variant="h3">{t('termsOfUsePage.title')}</HeadingText>
      <Typography variant="caption">{t('termsOfUsePage.update')}</Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.introduction.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans
          i18nKey={'termsOfUsePage.introduction.paragraph'}
          components={{
            mail: (
              <LinkText
                to={PATHS.FLEXDAY_SUPPORT}
                title={t('termsOfUsePage.mailSupport')}
              />
            ),
          }}
        />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.communications.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans
          i18nKey={'termsOfUsePage.communications.paragraph'}
          components={{
            mail: (
              <LinkText
                to={PATHS.FLEXDAY_SUPPORT}
                title={t('termsOfUsePage.mailSupport')}
              />
            ),
          }}
        />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.promotions.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.promotions.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.content.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.content.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.prohibitedUses.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.prohibitedUses.paragraph1'} />
        <br />
        <Divider />
        <Trans i18nKey={'termsOfUsePage.prohibitedUses.paragraph2'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.analytics.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.analytics.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.minorUse.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.minorUse.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.accounts.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.accounts.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.intellectualProperty.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.intellectualProperty.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.copyright.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans
          i18nKey={'termsOfUsePage.copyright.paragraph'}
          components={{
            mail: (
              <LinkText
                to={PATHS.FLEXDAY_SUPPORT}
                title={t('termsOfUsePage.mailSupport')}
              />
            ),
          }}
        />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.dmcaNotice.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans
          i18nKey={'termsOfUsePage.dmcaNotice.paragraph'}
          components={{
            mail: (
              <LinkText
                to={PATHS.FLEXDAY_SUPPORT}
                title={t('termsOfUsePage.mailSupport')}
              />
            ),
          }}
        />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.feedback.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans
          i18nKey={'termsOfUsePage.feedback.paragraph'}
          components={{
            mail: (
              <LinkText
                to={PATHS.FLEXDAY_SUPPORT}
                title={t('termsOfUsePage.mailSupport')}
              />
            ),
          }}
        />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.links.heading')}
      </HeadingText>
      <Typography variant="overline">
        <Trans
          i18nKey={'termsOfUsePage.links.paragraph'}
          components={{
            termslink: (
              <Link
                to={PATHS.TERMS_OF_USE}
                title={t('termsOfUsePage.termsOfUseLink')}
              />
            ),
            policymaker: (
              <LinkText
                to={PATHS.POLICY_MAKER}
                title={t('termsOfUsePage.policyMaker')}
              />
            ),
          }}
        />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.warranty.heading')}
      </HeadingText>
      <Typography variant="overline">
        <Trans i18nKey={'termsOfUsePage.warranty.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.liability.heading')}
      </HeadingText>
      <Typography variant="overline">
        <Trans i18nKey={'termsOfUsePage.liability.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.termination.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.termination.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.governingLaw.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.governingLaw.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.serviceChange.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.serviceChange.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.terms.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.terms.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.waiver.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.waiver.paragraph'} />
      </Typography>
      <HeadingText variant="h3">
        {t('termsOfUsePage.acknowledgement.heading')}
      </HeadingText>
      <Typography variant="body1">
        <Trans i18nKey={'termsOfUsePage.acknowledgement.paragraph'} />
      </Typography>
      <HeadingText variant="h6">
        {t('termsOfUsePage.contact.heading')}
      </HeadingText>
      <Typography variant="caption">
        <Trans
          i18nKey={'termsOfUsePage.contact.paragraph'}
          components={{
            mail: (
              <LinkText
                to={PATHS.FLEXDAY_MAIL}
                title={t('termsOfUsePage.mailSupport')}
              />
            ),
            weblink: (
              <LinkText
                to={PATHS.FLEXDAY_WEBSITE}
                title={t('termsOfUsePage.websiteLink')}
              />
            ),
            policymaker: (
              <LinkText
                to={PATHS.POLICY_MAKER}
                title={t('termsOfUsePage.policyMaker')}
              />
            ),
          }}
        />
      </Typography>
    </PageContainer>
  );
};

export default TermsOfUsePage;
