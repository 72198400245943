/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, styled } from '@mui/material';

export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(2),
  paddingTop: 0,
  color: theme.palette.poster.main,
  fill: theme.palette.poster.main,
}));

export const ImageContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  '& > img': {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

export const StyledTitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));
