/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import {
  UploadSection,
  DropZone,
  UploadIcon,
  UploadButtonText,
} from './fileSelector.styled';
import { ReactComponent as FileUploadIcon } from '../../assets/upload-icon.svg';
import { useTranslation } from 'react-i18next';

const FileSelector = ({
  label,
  onChange,
  fileTypes,
  maxFileSize,
  numberOfFiles,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onChange,
    accept: fileTypes,
    maxSize: maxFileSize,
    disabled: isDisabled,
  });

  return (
    <UploadSection>
      <DropZone
        disabled={isDisabled}
        isDragActive={isDragActive}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Stack spacing={1} my={5} alignItems="center">
          <UploadIcon disabled={isDisabled} component={FileUploadIcon} />
          {numberOfFiles > 0 ? (
            <Typography>
              {t('filesSelectedText', {
                filesNumber: numberOfFiles,
              })}
            </Typography>
          ) : (
            <UploadButtonText disabled={isDisabled}>{label}</UploadButtonText>
          )}

          <Button disabled={isDisabled} variant="contained" size="medium">
            {t('docIdPage.fileSelector.buttonText')}
          </Button>
        </Stack>
      </DropZone>
    </UploadSection>
  );
};

FileSelector.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  maxFileSize: PropTypes.number,
  numberOfFiles: PropTypes.number,
  isDisabled: PropTypes.bool,
};

export default FileSelector;
