/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, Box, Button } from '@mui/material';

export const FileCollectionsPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: theme.spacing(0),
}));
export const StyledFileUploadButton = styled(Button)(({ theme }) => ({
  float: 'right',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginTop: theme.spacing(0),
  },
}));
