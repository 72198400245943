/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useEffect, useState } from 'react';
import {
  SearchButton,
  AdornmentSearchIcon,
  SearchInput,
  SearchBoxContainer,
  SelectLanguage,
  LanguageFormControl,
} from './searchbar.styled';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import 'language-flags/index.css';
import './searchbar-custom-styles.css';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import PropTypes from 'prop-types';
import { useGetLanguagesQuery } from '../../redux/services/speciphicAsk';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown } from '@mui/icons-material';

const SearchbarBase = ({
  onSearchClick,
  value,
  onChange,
  placeholder,
  setQueryLanguage,
  langFromUrl,
}) => {
  const [language, setLanguage] = useState('');
  let [sortLanguages, setSortLanguages] = useState('');

  const [currentLanguage, setCurrentLanguage] = useState();
  const { t } = useTranslation();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (value.length !== 0) {
      onSearchClick(value);
    }
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleFormSubmit(e);
    }
  };

  const handleChangeLanguage = (e) => {
    setLanguage(e.target.value);
    setCurrentLanguage(
      sortLanguages.filter((lang) => lang.value === e.target.value)[0],
    );
  };

  const {
    data: languages = [],
    isError: isGetlanguagesError,
    isLoading: isGetlanguagesLoading,
    isSuccess: isGetlanguagesSuccess,
  } = useGetLanguagesQuery();

  useEffect(() => {
    currentLanguage && setQueryLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    let languagesTemp = [...languages];
    let sortLanguages = languagesTemp.sort((a, b) =>
      a.value.localeCompare(b.value),
    );

    if (langFromUrl !== undefined) {
      const matchedLanguage = sortLanguages.find(
        (lang) => lang.code === langFromUrl,
      );

      if (matchedLanguage) {
        sortLanguages = [
          matchedLanguage,
          ...sortLanguages.filter((lang) => lang.code !== langFromUrl),
        ];
      }
    }

    setSortLanguages(sortLanguages);
    languages.length && setLanguage(sortLanguages[0]?.value);
    setCurrentLanguage(sortLanguages[0]);
  }, [languages, langFromUrl]);

  return (
    <>
      <FormControl
        fullWidth
        sx={{
          display: { xs: 'block', md: 'none' },
          mb: 2,
          width: { xs: '100%', sm: '100%' },
        }}
        size="small"
      >
        <InputLabel size="small">
          {t('speciphicAskPage.searchbarBase.label')}
        </InputLabel>
        <Select
          value={language}
          disabled={language.length == 0}
          label="{t('speciphicAskPage.searchbarBase.label')}"
          onChange={handleChangeLanguage}
          sx={{ width: '100%' }}
          size="small"
          IconComponent={(props) => <KeyboardArrowDown {...props} />}
        >
          {sortLanguages.length &&
            sortLanguages.map((lang) => {
              return (
                <MenuItem key={lang.id} value={lang.value}>
                  {lang.value}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <SearchBoxContainer component="form" onSubmit={handleFormSubmit}>
        <SearchInput
          fullWidth
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          autoFocus
          sx={{ border: 'none', '& fieldset': { border: 'none' } }}
          value={value}
          onChange={handleSearchChange}
          endAdornment={
            <InputAdornment position="end">
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <LanguageFormControl variant="filled" size="small">
                  <InputLabel
                    sx={{
                      color: 'black',
                      paddingTop: 0,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                    size="small"
                  >
                    {t('speciphicAskPage.searchbarBase.label')}
                  </InputLabel>
                  <SelectLanguage
                    size="small"
                    value={language}
                    label={t('speciphicAskPage.searchbarBase.label')}
                    onChange={handleChangeLanguage}
                    IconComponent={(props) => <KeyboardArrowDown {...props} />}
                  >
                    {sortLanguages.length &&
                      sortLanguages.map((lang) => {
                        return (
                          <MenuItem key={lang.id} value={lang.value}>
                            <Grid container spacing={2}>
                              <Grid item container xs={6}>
                                <Typography
                                  variant="body1"
                                  className="lang-name"
                                  marginTop={2}
                                >
                                  {lang.value}
                                </Typography>
                              </Grid>
                              <Grid item container xs={6}>
                                <Typography
                                  marginTop={2.3}
                                  variant="body1"
                                  className={
                                    'lf ' +
                                    lang.value.toLowerCase() +
                                    ' lang-icon'
                                  }
                                ></Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        );
                      })}
                  </SelectLanguage>
                </LanguageFormControl>
              </Box>
              <SearchButton
                aria-label="search"
                onClick={handleFormSubmit}
                edge="end"
                color="primary"
              >
                <AdornmentSearchIcon component={SearchIcon} />
              </SearchButton>
            </InputAdornment>
          }
        />
      </SearchBoxContainer>
    </>
  );
};

SearchbarBase.propTypes = {
  onSearchClick: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setQueryLanguage: PropTypes.func,
  langFromUrl: PropTypes.string,
};

export default SearchbarBase;
