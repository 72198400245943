/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ modelId, collectionId }) => {
      return {
        url: `file-collections/${collectionId}/store`,
        method: 'POST',
        'Content-Type': 'application/json',
      };
    },
    invalidatesTags: ['individualFileCollections', 'fileCollections'],
  });
