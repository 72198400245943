/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.query({
    query: ({ exclude, include, isStoreCreated }) => {
      const params = new URLSearchParams();
      include?.forEach((item) => {
        params.append('include', item);
      });
      exclude?.forEach((item) => {
        params.append('exclude', item);
      });
      params.append('isStoreCreated', isStoreCreated);
      return {
        url: `file-collections?${params}`,
        method: 'GET',
      };
    },
    providesTags: ['fileCollections'],
  });
