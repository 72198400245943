/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.
 
 */
import React from 'react';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import {
  StyledToolbarGrid,
  StyledSearchIcon,
  StyledClearIcon,
  StyledDataGrid,
} from './datagrid.styled';

const DataGridTable = ({
  row,
  rowId,
  column,
  pageSize,
  handleRow,
  searchable,
  rowHeightAuto = false,
}) => {
  const CustomToolbar = () => {
    return (
      <StyledToolbarGrid
        container
        sx={{ display: searchable ? 'block' : 'none' }}
      >
        <Grid item xs={12} sm={4}>
          <GridToolbarQuickFilter />
        </Grid>
      </StyledToolbarGrid>
    );
  };

  return (
    <StyledDataGrid
      sx={{
        borderColor: 'white',
      }}
      getRowId={(row) => row[rowId]}
      autoHeight
      pageSize={pageSize}
      rows={row}
      columns={column}
      disableColumnMenu
      disableSelectionOnClick
      onRowClick={handleRow}
      components={{
        Toolbar: CustomToolbar,
        QuickFilterIcon: StyledSearchIcon,
        QuickFilterClearIcon: StyledClearIcon,
      }}
      getRowHeight={() => (rowHeightAuto ? 'auto' : null)}
    />
  );
};
DataGridTable.propTypes = {
  row: PropTypes.array,
  rowId: PropTypes.string,
  column: PropTypes.array,
  pageSize: PropTypes.number,
  handleRow: PropTypes.func,
  searchable: PropTypes.bool,
  rowHeightAuto: PropTypes.bool,
};

export default DataGridTable;
