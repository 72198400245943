import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useGetIndividualFileCollectionQuery } from '../../redux/services/speciphicAsk';

const FileCollection = ({ fileCollectionId }) => {
  const {
    data: fileCollection,
    isSuccess: isFileCollectionSuccess,
    isLoading: isFileCollectionLoading,
  } = useGetIndividualFileCollectionQuery({ collectionId: fileCollectionId });

  if (isFileCollectionLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isFileCollectionSuccess) {
    return <Typography>{fileCollection.name}</Typography>;
  }
};

FileCollection.propTypes = {
  fileCollectionId: PropTypes.string,
};

export default FileCollection;
