/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createAction, createReducer } from '@reduxjs/toolkit';

const setUserRole = createAction('set-UserRole');

const userRoleReducer = createReducer({}, (builder) => {
  builder.addCase(setUserRole, (state, action) => {
    return action.payload;
  });
});

export { setUserRole };
export default userRoleReducer;
