/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  Divider,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BorderedCell, HeadingText } from './privacyPolicy.styled';
import PageContainer from '../../components/pageContainer';
import LinkText from '../../components/linkText';
import * as PATHS from '../../constants/path';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <HeadingText variant="h3">{t('privacyPolicy.title')}</HeadingText>
      <Typography variant="body1">
        <Trans
          i18nKey="privacyPolicy.introduction"
          components={{
            weblink: (
              <LinkText
                to={PATHS.FLEXDAY_WEBSITE}
                title={t('privacyPolicy.websiteLink')}
              />
            ),
            termslink: (
              <Link
                to={PATHS.TERMS_OF_USE}
                title={t('privacyPolicy.termsOfUseLink')}
              />
            ),
            privacydownload: (
              <Link
                to={PATHS.PRIVACY_POLICY}
                title={t('privacyPolicy.privacyDownload')}
              />
            ),
          }}
        />
      </Typography>
      <HeadingText variant="h3">
        {t('privacyPolicy.definition.heading')}
      </HeadingText>
      <Typography variant="body1">
        {t('privacyPolicy.definition.paragraph')}
        <br />
        <Trans i18nKey="privacyPolicy.numberedHeading1" />
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <BorderedCell>{t('privacyPolicy.table.heading1')}</BorderedCell>
              <BorderedCell align="center">
                {t('privacyPolicy.table.heading2')}
              </BorderedCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={t('privacyPolicy.table.body.contactData')}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <BorderedCell component="th" scope="row">
                <Trans i18nKey="privacyPolicy.table.body.contactData" />
              </BorderedCell>
              <BorderedCell align="center">
                {t('privacyPolicy.table.body.serviceProviders')}
              </BorderedCell>
            </TableRow>
            <TableRow>
              <BorderedCell>
                <Trans i18nKey="privacyPolicy.table.body.accountData" />
              </BorderedCell>
              <BorderedCell align="center">
                {t('privacyPolicy.table.body.serviceProviders')}
              </BorderedCell>
            </TableRow>
            <TableRow>
              <BorderedCell>
                <Trans i18nKey="privacyPolicy.table.body.logData" />
              </BorderedCell>
              <BorderedCell align="center">
                {t('privacyPolicy.table.body.serviceProviders')}
              </BorderedCell>
            </TableRow>
            <TableRow>
              <BorderedCell>
                <Trans i18nKey="privacyPolicy.table.body.webAnalytics" />
              </BorderedCell>
              <BorderedCell align="center">
                {t('privacyPolicy.table.body.serviceProviders')}
              </BorderedCell>
            </TableRow>
            <TableRow>
              <BorderedCell>
                <Trans i18nKey="privacyPolicy.table.body.geologicalData" />
              </BorderedCell>
              <BorderedCell align="center">
                {t('privacyPolicy.table.body.serviceProviders')}
              </BorderedCell>
            </TableRow>
            <TableRow>
              <BorderedCell>
                <Trans i18nKey="privacyPolicy.table.body.publiclyAvailableContent" />
              </BorderedCell>
              <BorderedCell align="center">
                {t('privacyPolicy.table.body.serviceProviders')}
                <br />
                {t('privacyPolicy.table.body.users')}
              </BorderedCell>
            </TableRow>
          </TableBody>
        </Table>
      </Typography>

      <HeadingText variant="h3">
        {t('privacyPolicy.source.heading')}
      </HeadingText>
      <Typography variant="body1">
        {t('privacyPolicy.source.body')}
        <br />
        <Trans i18nKey="privacyPolicy.source.pointA.heading" />
        <br />
        <Trans i18nKey="privacyPolicy.source.pointA.points" />
        <br />
        <Divider />
        <Trans i18nKey="privacyPolicy.source.pointB.heading" />
        <br />
        <Trans i18nKey="privacyPolicy.source.pointB.points" />
        <br />
        <Divider />
        <Trans i18nKey="privacyPolicy.numberedHeading2" />
        <br />
        <Trans i18nKey="privacyPolicy.use.body1" />
        <br />
        <Trans i18nKey="privacyPolicy.use.points1" />
        <br />
        <Divider />
        <Trans i18nKey="privacyPolicy.use.body2" />
        <br />
        <Trans i18nKey="privacyPolicy.use.points2" />
        <br />
        <Divider />
        <Trans i18nKey="privacyPolicy.use.body3" />
        <br />
        <Trans i18nKey="privacyPolicy.use.points3" />
        <br />
        <Divider />
        <Trans i18nKey="privacyPolicy.use.body4" />
        <br />
        <Trans i18nKey="privacyPolicy.use.points4" />
        <br />
        <Divider />
        {t('privacyPolicy.use.afterBody')}
        <Divider />
        <Trans i18nKey="privacyPolicy.numberedHeading3" />
        <br />
        <Trans i18nKey="privacyPolicy.share.beforeBody" />
        <br />
        <Trans i18nKey="privacyPolicy.share.points1" />
        <br />
        <Trans i18nKey="privacyPolicy.share.points2" />
        <br />
        <Trans i18nKey="privacyPolicy.share.points3" />
        <br />
        <Trans i18nKey="privacyPolicy.share.points4" />
        <br />
      </Typography>

      <HeadingText variant="h3">{t('privacyPolicy.heading2')}</HeadingText>
      <Typography variant="body1">
        {t('privacyPolicy.toolsParagraph')}
      </Typography>

      <HeadingText variant="h3">{t('privacyPolicy.heading3')}</HeadingText>
      <Typography variant="body1">
        {t('privacyPolicy.dataParagraph.para1')}
        <br />
        <Trans i18nKey="privacyPolicy.dataParagraph.para2" />
        <br />
        {t('privacyPolicy.dataParagraph.para3')}
      </Typography>

      <HeadingText variant="h3">{t('privacyPolicy.heading4')}</HeadingText>
      <Typography variant="body1">
        <Trans
          i18nKey="privacyPolicy.changeParagraph"
          components={{
            weblink: (
              <LinkText
                to={PATHS.FLEXDAY_WEBSITE}
                title={t('privacyPolicy.websiteLink')}
              />
            ),
          }}
        />
      </Typography>

      <HeadingText variant="h3">{t('privacyPolicy.heading5')}</HeadingText>
      <Typography variant="overline">
        <Trans
          i18nKey="privacyPolicy.contactParagraph"
          components={{
            weblink: (
              <LinkText
                to={PATHS.FLEXDAY_WEBSITE}
                title={t('privacyPolicy.websiteLink')}
              />
            ),
          }}
        />
      </Typography>
    </PageContainer>
  );
};

export default PrivacyPolicyPage;
