/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';
import {
  Icon,
  Alert,
  Grid,
  Box,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FlexdayAskPoster } from '../../assets/flexday-ask-poster.svg';
import { ReactComponent as ResultsIcon } from '../../assets/results-svg.svg';
import { SearchbarBase } from '../../components/searchbar';
import { Answer, AnswerWithPdf } from '../../components/answer';
import AppTitle from '../../components/app.title';
import PageContainer from '../../components/pageContainer';
import BotpressChatbot from '../../components/Botpress';
import {
  useGetResultsMutation,
  useAddFeedbackMutation,
  useGetFaqMutation,
} from '../../redux/services/speciphicAsk';
import { pushToast } from '../../redux/reducers/toasts.slice';
import { useDispatch, useSelector } from 'react-redux';
import SearchSuggestion from './searchSuggestion';
import { setSelectedFileCollection } from '../../redux/reducers/fileCollection.slice';
import {
  ShadowBox,
  ShadowBoxNormal,
  AnswerComponentWrapper,
  AppTitleBox,
  LoadingTypography,
  ResultBox,
  FoundDataAlert,
  ResultTitle,
} from './search.page.styled';
import isChatbotPresent from '../../constants/chatbot';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import * as PATHS from '../../constants/path';
import {
  AZURE_OPENAI_SEARCH_ENGINE,
  GENERATIVE_SEARCH_ENGINE,
} from '../../constants/searchEngines';
import { useContext } from 'react';
import { FileCollectionContext } from '../../hooks/useContext';
import { Container } from '@mui/material';

const SearchPage = () => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [input, setInput] = useState('');
  const [page, setPage] = useState(1);
  const [answer, setAnswer] = useState([]);
  const [fileCollectionId, setFileCollectionId] = useState('');
  const [inputTextForAlert, setInputTextForAlert] = useState('');
  const [checked, setChecked] = useState(true);
  const [faqList, setFaqList] = useState([]);
  const [languageCode, setLanguageCode] = useState('');
  const [showInfographics, setShowInfographics] = useState(true);
  const [suggestiveSearch, setSuggestiveSearch] = useState(false);
  const fileCollection = useSelector((state) => state.fileCollection);
  const params = { lang: languageCode, id: fileCollectionId, q: input };
  const { getCollectionId } = useContext(FileCollectionContext);

  const [
    getFaq,
    {
      data: faqs = [],
      isError: isGetFaqError,
      isLoading: isGetFaqLoading,
      isSuccess: isGetFaqSuccess,
    },
  ] = useGetFaqMutation();

  useEffect(() => {
    getCollectionId() && getFaq({ collectionId: getCollectionId() });
    setFaqList(faqs);
  }, [getCollectionId()]);

  useEffect(() => {
    setFaqList(faqs);
  }, [faqs]);

  const [
    addFeedback,
    {
      data: feedbackData = [],
      isError: isAddFeedbackError,
      isLoading: isAddFeedbackLoading,
      isSuccess: isAddFeedbackSuccess,
    },
  ] = useAddFeedbackMutation();

  const [
    findAnswers,
    {
      data: result = [],
      isSuccess: isGetResultsSuccess,
      isLoading: isGetResultsLoading,
      isError: isGetResultsError,
    },
  ] = useGetResultsMutation();

  useEffect(() => {
    setAnswer(result);
  }, [isGetResultsSuccess]);

  useEffect(() => {
    if (isAddFeedbackSuccess) {
      dispatch(
        pushToast({
          message: t('speciphicAskPage.feedbackSuccess'),
          severity: 'success',
        }),
      );
    }
  }, [isAddFeedbackSuccess]);

  const handleLanguageChange = (value) => {
    setLanguageCode(value.code);
  };

  const handleClickFetchAnswer = () => {
    findAnswers({
      collectionId: getCollectionId(),
      query: input,
      acceptLanguage: languageCode,
    });
    setInputTextForAlert(input);
    setChecked(false);
    setShowInfographics(false);
    navigate({
      pathname: `${PATHS.HOMEPAGE}`,
      search: `${createSearchParams(params)}`,
    });
  };

  useEffect(() => {
    if (suggestiveSearch) {
      handleClickFetchAnswer();
      setSuggestiveSearch(false);
    }
  }, [suggestiveSearch]);

  //this useEffect for auto fetch answer for url
  useEffect(() => {
    if (!location.search) {
      setShowInfographics(true);
      setAnswer([]);
    }
    if (search.get('id')) {
      setInput(search.get('q'));
      setLanguageCode(search.get('lang'));
      findAnswers({
        collectionId: search.get('id'),
        query: search.get('q'),
        acceptLanguage: search.get('lang'),
      });
      setInputTextForAlert(search.get('q'));
      setChecked(false);
      setShowInfographics(false);
    }
  }, [location]);

  const handleClickFeedback = (res, bool) => {
    let resMetaJson = JSON.stringify(res.meta);
    {
      isGetResultsSuccess &&
        addFeedback({
          collection_id: getCollectionId(),
          query: input,
          answer: res.answer,
          meta: resMetaJson,
          isRight: bool,
        });
    }
  };

  const answersPerPage = 3;
  const lastAnswerIndex = page * answersPerPage;
  const firstAnswerIndex = lastAnswerIndex - answersPerPage;
  const currentAnswers = answer.slice(firstAnswerIndex, lastAnswerIndex);

  return (
    <>
      <PageContainer>
        {showInfographics && (
          <Typography
            variant="h1"
            sx={{
              fontSize: '56px',
              fontWeight: 200,
              color: '#131E29',
              marginTop: '100px',
            }}
          >
            Ask a Question
          </Typography>
        )}

        {/* <AppTitleBox position={'relative'}>
          {showInfographics && (
            <AppTitle
              checked={checked}
              image={
                <FlexdayAskPoster
                  style={{
                    width: '35%',
                    height: '35%',
                  }}
                />
              }
              subtitle={t('speciphicAskPage.appTitle.subtitle')}
            />
          )}
        </AppTitleBox> */}

        <Container sx={{ width: '100%', paddingX: { xs: 1, sm: 8 } }}>
          <>
            <ShadowBox>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <SearchbarBase
                    onSearchClick={handleClickFetchAnswer}
                    value={input}
                    setQueryLanguage={handleLanguageChange}
                    onChange={setInput}
                    placeholder={t(
                      'trainedquestionAnswerPage.searchbarBase.placeholder',
                    )}
                  />
                </Grid>
              </Grid>
            </ShadowBox>
            {showInfographics && (
              <SearchSuggestion
                isLoading={isGetFaqLoading}
                faqList={faqList}
                setInput={setInput}
                suggestiveTrue={setSuggestiveSearch}
              />
            )}
          </>

          {!showInfographics ? (
            <ResultBox>
              {isGetResultsLoading ? (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <LinearProgress sx={{ width: '40%' }} />
                  <LoadingTypography
                    variant="h5"
                    paddingTop={1}
                    color="primary"
                  >
                    {t('trainedquestionAnswerPage.loadingMessage')}
                  </LoadingTypography>
                </Box>
              ) : (
                <>
                  {isGetResultsSuccess && currentAnswers.length == 0 ? (
                    <Alert
                      severity="error"
                      sx={{
                        background: '#D11818',
                        color: '#FFFFFF',
                        '.MuiSvgIcon-root': { color: '#FFFFFF' },
                        borderRadius: 0,
                      }}
                    >
                      {t(
                        'trainedquestionAnswerPage.alertMessages.notFoundData',
                        {
                          searchText: inputTextForAlert,
                        },
                      )}
                    </Alert>
                  ) : (
                    <>
                      {currentAnswers.length > 0 && page == 1 && (
                        <>
                          <FoundDataAlert
                            severity="success"
                            sx={{
                              '.MuiSvgIcon-root': { color: '#FFFFFF' },
                              borderRadius: 0,
                            }}
                          >
                            {t(
                              'trainedquestionAnswerPage.alertMessages.foundData',
                              {
                                searchText: inputTextForAlert,
                              },
                            )}
                          </FoundDataAlert>
                        </>
                      )}
                      {currentAnswers.map((answer) => (
                        <AnswerComponentWrapper key={answer.answer}>
                          <ResultTitle>
                            {t('trainedquestionAnswerPage.resultHeader')}
                          </ResultTitle>
                          {fileCollection.searchEngine ===
                            GENERATIVE_SEARCH_ENGINE ||
                          fileCollection.searchEngine ===
                            AZURE_OPENAI_SEARCH_ENGINE ? (
                            <AnswerWithPdf
                              result={answer}
                              title={answer.answer}
                              description={answer.context}
                              metas={answer.meta}
                              pdfPreview={true}
                              feedback={handleClickFeedback}
                              query={input}
                            />
                          ) : (
                            <Answer
                              title={answer.answer}
                              description={answer.context}
                              meta={answer.originalFileName}
                              feedback={handleClickFeedback}
                              result={answer}
                              query={input}
                            />
                          )}
                        </AnswerComponentWrapper>
                      ))}
                    </>
                  )}
                </>
              )}
            </ResultBox>
          ) : null}
        </Container>
        {isChatbotPresent && <BotpressChatbot />}
      </PageContainer>
    </>
  );
};

export default SearchPage;
