/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  styled,
  Button,
} from '@mui/material';
import fontWeights from '../../themes/fontWeights';

export const ContentBox = styled(Box)(() => ({}));

export const CardContentBox = styled(CardContent)(({ theme }) => ({
  padding: 0,
  '&:last-child': {
    paddingBottom: theme.spacing(0),
  },
}));

export const StyledPdfBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.light}`,
  cursor: 'pointer',
  height: '240px',
  width: '186px',
  boxSizing: 'border-box',
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const StyleShareTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  color: theme.palette.text.caption,
  fontWeight: fontWeights.regular,
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(0),
  boxShadow: theme.shadows[25],
  padding: theme.spacing(2, 4, 0),
  boxSizing: `border-box`,
}));

export const StyledAnswerTypography = styled(Typography)(({ theme }) => ({
  fontWeight: fontWeights.light,
  fontSize: '18px',
  color: theme.palette.text.caption,
  lineHeight: '28px',
}));

export const StyledPdfContiner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  boxSizing: `border-box`,
  ':hover': {
    border: `1px solid ${theme.palette.primary.main} `,
    boxShadow: theme.shadows[27],
    borderRadius: `4px`,
  },
  padding: theme.spacing(1),
  width: 'fit-content',
  border: '1px solid transparent',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': { color: theme.palette.primary.main, background: 'none' },
}));

export const AnswerComponentBottomWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '9px 32px',
  margin: '25px -32px 0',
  background: '#f9f9f9',
}));

export const FeedBackWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const ShareWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
}));

export const AnsWrapper = styled(Box)(({ theme }) => ({}));
